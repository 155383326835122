import React from 'react'
import './LoginModal.css'
import InputCom from '../InputCom'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { login } from '../../../store/actions'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../../utils/response'

function LoginModal({ isOpen, onClose, children, checkout }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [loginForm, setLoginForm] = useState({
        email: '',
        password: '',
    })

    const [signupForm, setSignupForm] = useState({
        first_name: '',
        last_name: '',
        email: '',
        type: '',
        password: '',
        password_confirmation: '',
    })

    const modalClassName = isOpen ? 'modal-overlay open' : 'modal-overlay'

    const [modalType, setModalType] = useState('LOGIN')

    const showSignup = () => {
        setModalType('SIGNUP')
    }

    const showLogin = () => {
        setModalType('LOGIN')
    }

    const showForgotPassword = () => {
        setModalType('PASSWORD')
    }

    const close = () => {
        setModalType('LOGIN')
        onClose()
    }

    const onLogin = () => {
        let body = {
            email: loginForm.email,
            password: loginForm.password,
        }

        axios.post(`${BASE_URL}/login`, body).then((response) => {
            dispatch(login(response.data.data))
            onClose()
            if (checkout) {
                navigate('/checkout')
            } else {
                location.reload()
                window.scrollTo(0, 0)
            }
        })
    }

    const handleLoginInputChange = (e) => {
        const { name, value } = e.target

        setLoginForm({ ...loginForm, [name]: value })
    }

    const handleSignupInputChange = (e) => {
        const { name, value } = e.target

        setSignupForm({ ...loginForm, [name]: value })
    }

    const onSignup = () => {
        let body = {
            first_name: signupForm.first_name,
            last_name: signupForm.last_name,
            email: signupForm.email,
            type: 'Pro',
            password: signupForm.password,
            password_confirmation: signupForm.password_confirmation,
        }

        axios.post(`${BASE_URL}/register`, body).then((response) => {})
    }

    // const onPasswordReset = () => {
    //   axios.post(`${BASE_URL}/categories-listing-api`)
    //   .then(response => {

    //   })
    // }

    return (
        <div className={modalClassName}>
            {'LOGIN' == modalType && (
                <div className="modal-content-login">
                    <button onClick={close} className="close-button">
                        <span>
                            <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3L5 3.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L3.6 5L0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7C0.7 10.1 1.3 10.1 1.7 9.7L5 6.4L8.3 9.7C8.7 10.1 9.3 10.1 9.7 9.7C10.1 9.3 10.1 8.7 9.7 8.3L6.4 5L9.7 1.7C10.1 1.3 10.1 0.7 9.7 0.3Z"
                                    fill="#AAAAAA"
                                />
                            </svg>
                        </span>
                    </button>
                    <div className="lg:w-[572px] w-full  bg-white flex flex-col justify-center sm:p-10 p-5 border border-[#E0E0E0]">
                        <div className="w-full">
                            <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                                <h1 className="text-[34px] font-bold leading-[74px] text-qblack">
                                    Log In
                                </h1>
                            </div>
                            <div className="input-area">
                                <div className="input-item mb-5">
                                    <InputCom
                                        placeholder="example@buildlink.com"
                                        label="Email Address*"
                                        name="email"
                                        type="email"
                                        inputClasses="h-[50px]"
                                        value={loginForm.email}
                                        inputHandler={handleLoginInputChange}
                                    />
                                </div>
                                <div className="input-item mb-5">
                                    <InputCom
                                        placeholder="● ● ● ● ● ●"
                                        label="Password*"
                                        name="password"
                                        type="password"
                                        inputClasses="h-[50px]"
                                        value={loginForm.password}
                                        inputHandler={handleLoginInputChange}
                                    />
                                </div>
                                <div className="forgot-password-area flex justify-between items-center mb-7">
                                    <div className="remember-checkbox flex items-center space-x-2.5">
                                        {/* <button
                        onClick={rememberMe}
                        type="button"
                        className="w-5 h-5 text-qblack flex justify-center items-center border border-light-gray"
                      >
                        {checked && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </button> */}
                                        {/* <span
                        onClick={rememberMe}
                        className="text-base text-black"
                      >
                        Remember Me
                      </span> */}
                                    </div>
                                    {/* <a
                                  onClick={showForgotPassword}
                                  className="text-base"
                              >
                                  Forgot Password
                              </a> */}
                                </div>
                                <div className="signin-area mb-3.5">
                                    <div className="flex justify-center">
                                        <button
                                            type="button"
                                            onClick={onLogin}
                                            className="black-btn mb-6 text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center"
                                        >
                                            <span>Log In</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="signup-area flex justify-center">
                                    <p className="text-base text-qgraytwo font-normal">
                                        Don’t have an account?
                                        <a
                                            onClick={showSignup}
                                            className="ml-2 text-qblack"
                                        >
                                            Sign Up
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/*(false) && (<div className="modal-content-password">
              <button onClick={close} className="close-button">
              <span >
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3L5 3.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L3.6 5L0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7C0.7 10.1 1.3 10.1 1.7 9.7L5 6.4L8.3 9.7C8.7 10.1 9.3 10.1 9.7 9.7C10.1 9.3 10.1 8.7 9.7 8.3L6.4 5L9.7 1.7C10.1 1.3 10.1 0.7 9.7 0.3Z"
                        fill="#AAAAAA"
                      />
                    </svg>
                  </span>
              </button>
              <div className="lg:w-[572px] w-full  bg-white flex flex-col justify-center sm:p-10 p-5 border border-[#E0E0E0]">
                  <div className="w-full">
                      <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                          <h1 className="text-[34px] font-bold leading-[74px] text-qblack">
                              Reset Password
                          </h1>
                      </div>
                      <div className="input-area">
                          <div className="input-item mb-5">
                              <InputCom
                                  placeholder="example@buildlink.com"
                                  label="Email Address*"
                                  name="email"
                                  type="email"
                                  inputClasses="h-[50px]"
                              />
                          </div>
                          <div className="forgot-password-area flex justify-between items-center mb-7">
                              <div className="remember-checkbox flex items-center space-x-2.5">
                                  {/* <button
                        onClick={rememberMe}
                        type="button"
                        className="w-5 h-5 text-qblack flex justify-center items-center border border-light-gray"
                      >
                        {checked && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </button> 
                                  <span
                        onClick={rememberMe}
                        className="text-base text-black"
                      >
                        Remember Me
                      </span> 
                              </div>
                          </div>
                          <div className="signin-area mb-3.5">
                              <div className="flex justify-center">
                                  <button
                                      type="button"
                                      className="black-btn mb-6 text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center"
                                  >
                                      <span>Send Reset Password Email</span>
                                  </button>
                              </div>
                          </div>
                          <div className="signup-area flex justify-center">
                              <p className="text-base text-qgraytwo font-normal">
                                  Go to 
                                  <a onClick={showLogin} className="ml-2 text-qblack">
                                      Login
                                  </a>
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>)*/}

            {'SIGNUP' === modalType && (
                <div className="modal-content-signup">
                    <button onClick={close} className="close-button">
                        <span>
                            <svg
                                width="10"
                                height="10"
                                viewBox="0 0 10 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3L5 3.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L3.6 5L0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7C0.7 10.1 1.3 10.1 1.7 9.7L5 6.4L8.3 9.7C8.7 10.1 9.3 10.1 9.7 9.7C10.1 9.3 10.1 8.7 9.7 8.3L6.4 5L9.7 1.7C10.1 1.3 10.1 0.7 9.7 0.3Z"
                                    fill="#AAAAAA"
                                />
                            </svg>
                        </span>
                    </button>
                    <div className="lg:w-[572px] w-full  bg-white flex flex-col justify-center sm:p-10 p-5 border border-[#E0E0E0]">
                        <div className="w-full">
                            <div className="title-area flex flex-col justify-center items-center relative text-center mb-7">
                                <h1 className="text-[34px] font-bold leading-[74px] text-qblack">
                                    Sign Up
                                </h1>
                            </div>
                            <div className="input-area">
                                <div className="input-two-group-horizontal">
                                    <div className="input-item mr-5 mb-5">
                                        <InputCom
                                            placeholder="Alexander"
                                            label="First Name*"
                                            name="first_name"
                                            type="email"
                                            inputClasses="h-[50px]"
                                            value={signupForm.first_name}
                                            inputHandler={
                                                handleSignupInputChange
                                            }
                                        />
                                    </div>
                                    <div className="input-item mr-5 mb-5">
                                        <InputCom
                                            placeholder="Smith"
                                            label="Last Name*"
                                            name="last_name"
                                            type="email"
                                            inputClasses="h-[50px]"
                                            value={signupForm.last_name}
                                            inputHandler={
                                                handleSignupInputChange
                                            }
                                        />
                                    </div>
                                </div>

                                <div className="input-item mb-5 mr-5">
                                    <InputCom
                                        placeholder="example@buildlink.com"
                                        label="Email Name*"
                                        name="email"
                                        type="email"
                                        inputClasses="h-[50px]"
                                        value={signupForm.email}
                                        inputHandler={handleSignupInputChange}
                                    />
                                </div>
                                <div className="input-two-group-horizontal">
                                    <div className="input-item mb-5 mr-5">
                                        <InputCom
                                            placeholder="● ● ● ● ● ●"
                                            label="Enter Password*"
                                            name="password"
                                            type="password"
                                            inputClasses="h-[50px]"
                                            value={signupForm.password}
                                            inputHandler={
                                                handleSignupInputChange
                                            }
                                        />
                                    </div>
                                    <div className="input-item mb-5 mr-5">
                                        <InputCom
                                            placeholder="● ● ● ● ● ●"
                                            label="Re-enter Password*"
                                            name="password_confirmation"
                                            type="password"
                                            inputClasses="h-[50px]"
                                            value={
                                                signupForm.password_confirmation
                                            }
                                            inputHandler={
                                                handleSignupInputChange
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="forgot-password-area flex justify-between items-center mb-7">
                                    <div className="remember-checkbox flex items-center space-x-2.5">
                                        {/* <button
                        onClick={rememberMe}
                        type="button"
                        className="w-5 h-5 text-qblack flex justify-center items-center border border-light-gray"
                      >
                        {checked && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </button> */}
                                        {/* <span
                        onClick={rememberMe}
                        className="text-base text-black"
                      >
                        Remember Me
                      </span> */}
                                    </div>
                                </div>
                                <div className="signin-area mb-3.5">
                                    <div className="flex justify-center">
                                        <button
                                            type="button"
                                            className="black-btn mb-6 text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center"
                                            onClick={onSignup}
                                        >
                                            <span>Sign Up</span>
                                        </button>
                                    </div>
                                </div>
                                <div className="signup-area flex justify-center">
                                    <p className="text-base text-qgraytwo font-normal">
                                        Have an account?
                                        <a
                                            onClick={showLogin}
                                            className="ml-2 text-qblack"
                                        >
                                            Login
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default LoginModal
