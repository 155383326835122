import AOS from 'aos'
import 'aos/dist/aos.css'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import store from './store'
import App from './App'
import './index.css'
import { Provider } from 'react-redux'

AOS.init()

const root = document.getElementById('root')
ReactDOM.createRoot(root).render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
)
