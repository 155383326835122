import { Link } from "react-router-dom";
import Arrow from "../../../Helpers/icons/Arrow";
import Selectbox from "../../../Helpers/Selectbox";
import { useDispatch, useSelector } from "react-redux";
import ThinPeople from "../../../Helpers/icons/ThinPeople";
import LoginModal from "../../../Helpers/LoginModal/LoginModal";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PORTAL_URL } from "../../../../utils/response";

export default function TopBar({ className }) {
	const shopUserData = useSelector((state) => state.shopUserData);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const navigate = useNavigate();

	const openModal = () => {
		setIsModalOpen(true);
		console.log(isModalOpen);
	};

	const closeModal = () => {
		setIsModalOpen(false);
	};

	const openProfile = () => {
		navigate("/profile");
	};

	return (
		<>
			<div
				className={`w-full bg-white h-10 border-b border-qgray-border ${
					className || ""
				}`}
			>
				<div className="container-x mx-auto h-full">
					<div className="flex justify-between items-center h-full">
						<div className="topbar-nav">
							<ul className="flex space-x-6">
								<a href={`${PORTAL_URL}/login?from=shop`}>
									<span
										style={{
											backgroundColor: "#005699",
											color: "white",
											padding: "0.4rem 1rem 0.4rem 1rem",
											borderRadius: "0.2rem",
										}}
										className="text-xs leading-6 text-qblack font-500"
									>
										Go to BuildLink <i class="arrow right"></i>
									</span>
								</a>
								{shopUserData.shopUserData?.token ? (
									<li>
										<Link to="/profile#order">
											<span className="text-xs leading-6 text-qblack font-500">
												Orders
											</span>
										</Link>
									</li>
								) : (
									""
								)}
								{/* <li>
									<Link to="/faq">
										<span className="text-xs leading-6 text-qblack font-500">
											Support
										</span>
									</Link>
								</li> */}
							</ul>
							<ul className="flex space-x-6">
								<li></li>
							</ul>
						</div>
						<div className="topbar-dropdowns sm:block ">
							<div className="flex space-x-6">
								{/* <div className="country-select flex space-x-1 items-center">
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/country-logo-16x16.png`}
                      width="16"
                      height="16"
                      alt="country logo"
                      className="overflow-hidden rounded-full"
                    />
                  </div>
                  <Selectbox
                    className="w-fit"
                    datas={["United State", "Bangladesh", "India"]}
                  />
                  <div>
                    <Arrow className="fill-current qblack" />
                  </div>
                </div> */}
								{/* <div className="currency-select flex space-x-1 items-center">
                  <Selectbox className="w-fit" datas={["USD", "BDT"]} />
                  <Arrow className="fill-current qblack" />
                </div> */}
								<div className="language-select flex space-x-1 items-center">
									<ThinPeople />
									{shopUserData.shopUserData?.token ? (
										<span
											className="text-xs leading-6 text-qblack font-500"
											style={{ cursor: "pointer" }}
											onClick={openProfile}
										>
											{shopUserData.shopUserData?.name}
										</span>
									) : (
										<span
											className="text-xs leading-6 text-qblack font-500"
											style={{ cursor: "pointer" }}
											onClick={openModal}
										>
											Login
										</span>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<LoginModal
				style={{ zIndex: "31" }}
				isOpen={isModalOpen}
				onClose={closeModal}
			></LoginModal>
		</>
	);
}
