import { useEffect, useState } from 'react'
import Star from '../Helpers/icons/Star'
import AlertToast from '../AlertToast/AlertToast'
import { useDispatch, useSelector } from 'react-redux'
import { removeFromCart, addToCart, showToast } from '../../store/actions'
import Selectbox from '../Helpers/Selectbox'

import axios from 'axios'
import { BASE_URL } from '../../utils/response'

export default function ProductView({ className, reportHandler, productSlug }) {
    const [src, setSrc] = useState([])

    const changeImgHandler = (current) => {
        setSrc(product?.product_detail?.ImgURL + '' + current)
    }

    const [tab, setTab] = useState('review')
    const [quantity, setQuantity] = useState(1)
    const [color, setColor] = useState(null)
    const [size, setSize] = useState(null)
    const [price, setPrice] = useState(null)
    const [variant, setVariant] = useState(null)
    const [product, setProduct] = useState([])
    const [productsImg, setProductsImg] = useState([])
    const [productsColor, setProductsColor] = useState([])
    const [productsSize, setProductsSize] = useState([])
    const [productsVariant, setProductVariant] = useState([])
    const [starRating, setStarRating] = useState([1, 1, 1, 1, 1])
    const [reviews, setReviews] = useState([])

    const [rating, setRating] = useState({
        count: 0,
        average: 0,
    })

    const changeColorHandler = (current) => {
        setColor(current)

        let variant = productsVariant
            .filter((x) => x.is_available == 1)
            .find(
                (x) =>
                    x.measurement_size ==
                        productsSize
                            //.filter((x) => x.is_available == 1)
                            .find((x) => x.title == size.title)?.title &&
                    x.color_title == current.color.label
            )
        setVariant(variant)
        setPrice(variant.quantity > 0 ? variant.price : null)
        setSrc(product?.product_detail?.ImgURL + '' + current.image)
    }

    const changeSizeHandler = (current) => {
        console.log(current)

        let colors = productsVariant
            .filter((variant) => variant.measurement_size == current.title)
            .map((variant) => {
                let color = productsColor.find(
                    (x) => x.color.label === variant.color_title
                )

                return { ...color, is_available: variant.is_available }
            })
        let sortedColors = colors
            .filter((x) => x.is_available == 1)
            .concat(colors.filter((x) => x.is_available == 0))

        setProductsColor(sortedColors)
        setColor(sortedColors.filter((x) => x.is_available == 1)[0])
        setSize(current)

        let variant = productsVariant.find(
            (x) =>
                x.color_title ==
                    sortedColors.filter((x) => x.is_available == 1)[0]?.color
                        ?.title && x.measurement_size == current.title
        )
        setVariant(variant)
        setPrice(variant.quantity > 0 ? variant.price : null)
        setDropdownOpen(!isDropdownOpen)
    }

    const increment = () => {
        if (quantity < variant.quantity) setQuantity((prev) => prev + 1)
        else showToastMessage('Stock limit reached', 'warning')
    }
    const decrement = () => {
        if (quantity > 1) {
            setQuantity((prev) => prev - 1)
        }
    }

    const dispatch = useDispatch()

    const handlAddToCart = (item) => {
        const product = {
            slug: item.slug,
            name: item.title,
            image:
                item.ImgURL +
                '' +
                item.images.find((img) => img.is_thumbnail)?.image,
            quantity: quantity,
            color: {
                code: color?.color?.code,
                label: color?.color?.title,
                slug: color?.color?.slug,
            },
            size: {
                id: size.id,
                height: size.height,
                width: size.width,
                length: size.length,
                weight: size.weight,
                slug: size.slug,
                size: size.title,
            },
            variant: variant,

            price: price,
        }

        dispatch(addToCart(product))
        showToastMessage('Product added to cart', 'success')
    }

    let defaultColor = {
        color: {
            label: 'Default',
        },
    }

    let defaultSize = {
        title: 'Default',
        length: 0,
        width: 0,
        height: 0,
        depth: 0,
        weight: 0,
    }

    useEffect(() => {
        axios
            .get(`${BASE_URL}/products-listing-api/${productSlug}`)
            .then((response) => {
                setProduct(response.data.data)
                setProductsImg([
                    ...response.data.data.product_detail.images,
                    ...response.data.data.product_detail.colors?.map((x) => {
                        return { image: x.image, is_thumbnail: 0 }
                    }),
                ])

                setProductVariant(response.data.data.product_detail.variants)

                let preselectedVariant =
                    response.data.data.product_detail.variants.filter(
                        (x) => x.is_available == 1
                    )[0]

                let colors = response.data.data.product_detail.variants
                    .filter(
                        (variant) =>
                            variant.measurement_size ==
                            preselectedVariant.measurement_size
                    )
                    .filter((variant) => variant.color_title != 'Default')
                    .map((variant) => {
                        let colors = [
                            ...response.data.data.product_detail.colors,
                        ]

                        let color = colors.find(
                            (x) => x.color.label === variant.color_title
                        )

                        return { ...color, is_available: variant.is_available }
                    })

                let sortedColors = colors
                    .filter((x) => x.is_available == 1)
                    .concat(colors.filter((x) => x.is_available == 0))
                setProductsColor(sortedColors)

                let sizes = [...response.data.data.product_detail.measurements]

                setProductsSize(sizes)
                setSrc(
                    response.data.data.product_detail?.ImgURL +
                        '' +
                        response.data.data.product_detail.images.find(
                            (img) => img.is_thumbnail
                        )?.image
                )
                setColor(
                    sortedColors.length === 0 ? defaultColor : sortedColors[0]
                )
                setSize(sizes.length === 0 ? defaultSize : sizes[0])

                let variant = response.data.data.product_detail.variants.find(
                    (x) =>
                        x.measurement_size ==
                            (sizes.length === 0 ? defaultSize : sizes[0])
                                .title &&
                        x.color_title ==
                            (sortedColors.length === 0
                                ? defaultColor
                                : sortedColors[0]
                            )?.color?.label
                )

                setVariant(variant)
                setPrice(variant.quantity > 0 ? variant.price : null)

                let count = response.data.data.product_detail.reviews.length

                let total = 0
                response.data.data.product_detail.reviews.forEach((item) => {
                    total += +item.avg_rating
                })

                let average = count !== 0 ? Number(total / count).toFixed(1) : 0
                setRating({ count: count, average: average })

                let newStarRating = starRating.map((item, i) => {
                    if (i < Math.floor(average)) return 1
                    else {
                        return 0
                    }
                })
                setStarRating(newStarRating)
                setReviews(response.data.data.product_detail.reviews)
            })
    }, [])

    const showToastMessage = (message, type) => {
        dispatch(showToast(message, type, 3000))
    }

    const [item, setItem] = useState('')
    const [toggle, setToggle] = useState(false)
    const handler = (e, value) => {
        if (action) {
            action(value)
        }
        setItem(value)
        setToggle(!toggle)
    }

    const [isDropdownOpen, setDropdownOpen] = useState(false)

    return (
        <div>
            <div
                className={`product-view w-full lg:flex justify-between ${
                    className || ''
                }`}
            >
                <div
                    data-aos="fade-right"
                    className="lg:w-1/2 xl:mr-[70px] lg:mr-[50px]"
                >
                    <div className="w-full">
                        <div className="w-full h-[600px] border border-qgray-border flex justify-center items-center overflow-hidden relative mb-3">
                            <img
                                src={`${src}`}
                                alt=""
                                className="object-contain"
                            />
                        </div>
                        <div className="flex gap-2 flex-wrap">
                            {productsImg &&
                                productsImg?.length > 0 &&
                                productsImg?.map((img, index) => (
                                    <div
                                        onClick={() =>
                                            changeImgHandler(img?.image)
                                        }
                                        key={index}
                                        className="w-[110px] h-[110px] p-[15px] border border-qgray-border cursor-pointer"
                                    >
                                        <img
                                            src={`${product?.product_detail?.ImgURL}${img?.image}`}
                                            alt=""
                                            className={`w-full h-full object-contain ${
                                                '' !== img?.image
                                                    ? 'opacity-50'
                                                    : ''
                                            } `}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
                <div className="flex-1">
                    <div className="product-details w-full mt-10 lg:mt-0">
                        <span className="text-qgray text-xs font-normal uppercase tracking-wider mb-2 inline-block"></span>
                        <p className="text-xl font-medium text-qblack">
                            {product?.product_detail?.title}
                        </p>
                        <span
                            style={{ fontSize: '0.6rem', marginBottom: '6rem' }}
                        >
                            Model No. {variant?.model_no}
                        </span>

                        <div className="flex space-x-[10px] items-center mb-6">
                            <div className="flex">
                                {starRating.map((item) => {
                                    return (
                                        <span
                                            className={
                                                item == 0
                                                    ? 'text-qgray'
                                                    : 'text-qyellow'
                                            }
                                        >
                                            <svg
                                                width="19"
                                                height="18"
                                                viewBox="0 0 19 18"
                                                fill="none"
                                                className="fill-current"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M9.5 0L11.6329 6.56434H18.535L12.9511 10.6213L15.084 17.1857L9.5 13.1287L3.91604 17.1857L6.04892 10.6213L0.464963 6.56434H7.36712L9.5 0Z" />
                                            </svg>
                                        </span>
                                    )
                                })}
                            </div>
                            <span className="text-[13px] font-normal text-qblack">
                                {rating.average} Rating
                            </span>

                            <span className="text-[13px] font-normal text-qblack">
                                {rating.count} Reviews
                            </span>
                        </div>

                        <div className="flex space-x-2 items-center mb-7">
                            <span className="text-2xl font-500">
                                {price ? '$' + price : 'Out of stock'}
                            </span>
                        </div>

                        <p className="text-qgray text-sm text-normal mb-[30px] leading-7">
                            {product?.product_detail?.description}
                        </p>

                        {productsColor.length !== 0 && (
                            <div className="colors mb-[30px]">
                                <span className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block">
                                    COLOR
                                </span>
                                {color && (
                                    <span className="ml-2">
                                        {color?.color?.title}
                                    </span>
                                )}

                                <div>
                                    {' '}
                                    <span className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block">
                                        FINISH
                                    </span>
                                    {color && (
                                        <span className="ml-2">
                                            {color?.finishings
                                                ?.map((x) => x.finishing.label)
                                                .join(' / ')}
                                        </span>
                                    )}
                                </div>

                                <div className="flex space-x-4 items-center">
                                    {productsColor &&
                                        productsColor.length > 0 &&
                                        productsColor.map((c, index) => (
                                            <div key={index}>
                                                {c.color && c.color !== '' && (
                                                    <button
                                                        disabled={
                                                            !c.is_available
                                                        }
                                                        onClick={() =>
                                                            changeColorHandler(
                                                                c
                                                            )
                                                        }
                                                        style={{
                                                            backgroundImage: `url(
														"${product?.product_detail?.ImgURL}${c?.image}"
													)`,
                                                            backgroundSize:
                                                                'cover',
                                                            backgroundRepeat:
                                                                'no-repeat',
                                                            width: '60px',
                                                            height: '60px',
                                                            padding: '4px',

                                                            borderBottom: `${'8px'} solid ${c.color.code}`,
                                                            borderRadius: `${
                                                                c.color.code ==
                                                                color?.color
                                                                    ?.code
                                                                    ? '14px'
                                                                    : '4px'
                                                            }`,

                                                            cursor: c.is_available
                                                                ? 'pointer'
                                                                : 'not-allowed',
                                                            filter: c.is_available
                                                                ? 'none'
                                                                : 'grayscale(100%)',
                                                        }}
                                                    ></button>
                                                )}
                                            </div>
                                        ))}
                                </div>
                                {!color && (
                                    <i
                                        style={{
                                            fontSize: '0.6rem',
                                            color: '#cc0000',
                                        }}
                                    >
                                        Colors not available
                                    </i>
                                )}
                            </div>
                        )}
                        {productsSize.length !== 0 && (
                            <div className="colors mb-[30px]">
                                <span
                                    onClick={() => setToggle(!toggle)}
                                    className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block"
                                >
                                    SIZE
                                </span>

                                <div className="flex space-x-4 items-center">
                                    {productsSize.length > 0 && (
                                        <div className="relative inline-block text-left">
                                            <div>
                                                <span className="">
                                                    <button
                                                        type="button"
                                                        className="inline-flex justify-center w-full px-4 py-6 space-x-10 items-center"
                                                        id="options-menu"
                                                        onClick={() =>
                                                            setDropdownOpen(
                                                                !isDropdownOpen
                                                            )
                                                        }
                                                    >
                                                        {size
                                                            ? `${size.title} - ${size.height}" x ${size.width}" x ${size.length}"  | ${size.weight} Kg`
                                                            : 'Select an option  '}
                                                        <div
                                                            style={{
                                                                paddingLeft:
                                                                    '1rem',
                                                            }}
                                                        >
                                                            <svg
                                                                width="11"
                                                                height="7"
                                                                viewBox="0 0 11 7"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M5.4 6.8L0 1.4L1.4 0L5.4 4L9.4 0L10.8 1.4L5.4 6.8Z"
                                                                    fill="#222222"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </button>
                                                </span>
                                            </div>

                                            {isDropdownOpen && (
                                                <div className="origin-top-left absolute left-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                                                    <div
                                                        className="py-1"
                                                        role="menu"
                                                        aria-orientation="vertical"
                                                        aria-labelledby="options-menu"
                                                    >
                                                        {productsSize.map(
                                                            (option) => (
                                                                <button
                                                                    key={
                                                                        option.value
                                                                    }
                                                                    onClick={() =>
                                                                        changeSizeHandler(
                                                                            option
                                                                        )
                                                                    }
                                                                    className="block w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                                    role="menuitem"
                                                                >
                                                                    {`${option.title} - ${option.height}" x ${option.width}" x ${option.length}" |  ${option.weight} Kg`}
                                                                </button>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {!size && (
                                    <div>
                                        <i
                                            style={{
                                                fontSize: '0.6rem',
                                                color: '#cc0000',
                                            }}
                                        >
                                            Please select size
                                        </i>
                                    </div>
                                )}
                            </div>
                        )}
                        {/* <div  className="product-size mb-[30px]">
            <span className="text-sm font-normal uppercase text-qgray mb-[14px] inline-block">
              SIZE
            </span>
            <div className="w-full">
              <div className=" border border-qgray-border h-[50px] flex justify-between items-center px-6 cursor-pointer">
                <Selectbox
                  className="w-full"
                  datas={["Small", "Medium", "Large", "Extra Large"]}
                >
                  {({ item }) => (
                    <>
                      <div>
                        <span className="text-[13px] text-qblack">{item}</span>
                      </div>
                      <div className="flex space-x-10 items-center">
                        <span className="text-[13px] text-qblack">
                          3”W x 3”D x 7”H
                        </span>
                        <span>
                          <svg
                            width="11"
                            height="7"
                            viewBox="0 0 11 7"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M5.4 6.8L0 1.4L1.4 0L5.4 4L9.4 0L10.8 1.4L5.4 6.8Z"
                              fill="#222222"
                            />
                          </svg>
                        </span>
                      </div>
                    </>
                  )}
                </Selectbox>
              </div>
            </div>
          </div> */}

                        <div
                            //
                            className="quantity-card-wrapper w-full flex items-center h-[50px] space-x-[10px] mb-[30px]"
                        >
                            <div className="w-[120px] h-full px-[26px] flex items-center border border-qgray-border">
                                <div className="flex justify-between items-center w-full">
                                    <button
                                        onClick={decrement}
                                        type="button"
                                        className="text-base text-qgray"
                                    >
                                        -
                                    </button>
                                    <span className="text-qblack">
                                        {quantity}
                                    </span>
                                    <button
                                        onClick={increment}
                                        type="button"
                                        className="text-base text-qgray"
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                            <div className="w-[60px] h-full flex justify-center items-center border border-qgray-border">
                                <button type="button">
                                    <span>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M17 1C14.9 1 13.1 2.1 12 3.7C10.9 2.1 9.1 1 7 1C3.7 1 1 3.7 1 7C1 13 12 22 12 22C12 22 23 13 23 7C23 3.7 20.3 1 17 1Z"
                                                stroke="#D5D5D5"
                                                strokeWidth="2"
                                                strokeMiterlimit="10"
                                                strokeLinecap="square"
                                            />
                                        </svg>
                                    </span>
                                </button>
                            </div>
                            <div className="flex-1 h-full">
                                <button
                                    disabled={!price}
                                    onClick={() =>
                                        handlAddToCart(product.product_detail)
                                    }
                                    type="button"
                                    className="black-btn text-sm font-semibold w-full h-full"
                                >
                                    Add To Cart
                                </button>
                            </div>
                        </div>

                        <div className="mb-[20px]">
                            <p className="text-[13px] text-qgray leading-7">
                                <span className="text-qblack">Category :</span>{' '}
                                {product?.product_detail?.category?.label}
                            </p>
                            {/* <p className="text-[13px] text-qgray leading-7">
              <span className="text-qblack">Tags :</span> Beer, Foamer
            </p> */}
                            <p className="text-[13px] text-qgray leading-7">
                                <span className="text-qblack">SKU:</span>{' '}
                                {product?.product_detail?.sku}
                            </p>
                            <p className="text-[13px] text-qgray leading-7">
                                <span className="text-qblack">Brand:</span>{' '}
                                {product?.product_detail?.brand?.title}
                            </p>
                        </div>

                        {/* <div
            
            className="flex space-x-2 items-center mb-[20px]"
          >
            <span>
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 0C0.247634 0 0.475436 0 0.729172 0C0.738324 0.160174 0.747477 0.316279 0.757647 0.493233C1.05816 0.392044 1.33885 0.282211 1.62818 0.203395C3.11296 -0.201361 4.51385 0.0366111 5.84202 0.779512C6.47661 1.13494 7.14171 1.39071 7.86987 1.47207C8.88125 1.58496 9.82093 1.35817 10.7098 0.88426C10.9335 0.765274 11.1522 0.636627 11.411 0.491199C11.4161 0.606117 11.4237 0.693577 11.4237 0.780529C11.4242 3.18822 11.4222 5.5954 11.4288 8.00309C11.4293 8.1892 11.3718 8.29089 11.2096 8.38039C9.31956 9.42279 7.4285 9.43499 5.54557 8.37734C4.06231 7.54443 2.55363 7.43307 0.992568 8.13835C0.804428 8.22327 0.737816 8.33005 0.739341 8.53904C0.749003 9.9206 0.744426 11.3027 0.744426 12.6842C0.744426 12.7849 0.744426 12.8851 0.744426 13C0.48764 13 0.254244 13 0 13C0 8.67582 0 4.34961 0 0Z"
                  fill="#EB5757"
                />
              </svg>
            </span>

            <button
              type="button"
              onClick={reportHandler}
              className="text-qred font-semibold text-[13px]"
            >
              Report This Item
            </button>
          </div> */}

                        {/* <div
            
            className="social-share flex  items-center w-full"
          >
            <span className="text-qblack text-[13px] mr-[17px] inline-block">
              Share This
            </span>

            <div className="flex space-x-5 items-center">
              <span>
                <svg
                  width="10"
                  height="16"
                  viewBox="0 0 10 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 16V9H0V6H3V4C3 1.3 4.7 0 7.1 0C8.3 0 9.2 0.1 9.5 0.1V2.9H7.8C6.5 2.9 6.2 3.5 6.2 4.4V6H10L9 9H6.3V16H3Z"
                    fill="#3E75B2"
                  />
                </svg>
              </span>
              <span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 0C3.6 0 0 3.6 0 8C0 11.4 2.1 14.3 5.1 15.4C5 14.8 5 13.8 5.1 13.1C5.2 12.5 6 9.1 6 9.1C6 9.1 5.8 8.7 5.8 8C5.8 6.9 6.5 6 7.3 6C8 6 8.3 6.5 8.3 7.1C8.3 7.8 7.9 8.8 7.6 9.8C7.4 10.6 8 11.2 8.8 11.2C10.2 11.2 11.3 9.7 11.3 7.5C11.3 5.6 9.9 4.2 8 4.2C5.7 4.2 4.4 5.9 4.4 7.7C4.4 8.4 4.7 9.1 5 9.5C5 9.7 5 9.8 5 9.9C4.9 10.2 4.8 10.7 4.8 10.8C4.8 10.9 4.7 11 4.5 10.9C3.5 10.4 2.9 9 2.9 7.8C2.9 5.3 4.7 3 8.2 3C11 3 13.1 5 13.1 7.6C13.1 10.4 11.4 12.6 8.9 12.6C8.1 12.6 7.3 12.2 7.1 11.7C7.1 11.7 6.7 13.2 6.6 13.6C6.4 14.3 5.9 15.2 5.6 15.7C6.4 15.9 7.2 16 8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0Z"
                    fill="#E12828"
                  />
                </svg>
              </span>
              <span>
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 18 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.0722 1.60052C16.432 1.88505 15.7562 2.06289 15.0448 2.16959C15.7562 1.74278 16.3253 1.06701 16.5742 0.248969C15.8985 0.640206 15.1515 0.924742 14.3335 1.10258C13.6933 0.426804 12.7686 0 11.7727 0C9.85206 0 8.28711 1.56495 8.28711 3.48557C8.28711 3.7701 8.32268 4.01907 8.39382 4.26804C5.51289 4.12577 2.9165 2.73866 1.17371 0.604639C0.889175 1.13814 0.71134 1.70722 0.71134 2.34742C0.71134 3.5567 1.31598 4.62371 2.27629 5.26392C1.70722 5.22835 1.17371 5.08608 0.675773 4.83711V4.87268C0.675773 6.5799 1.88505 8.00258 3.48557 8.32268C3.20103 8.39382 2.88093 8.42938 2.56082 8.42938C2.34742 8.42938 2.09845 8.39382 1.88505 8.35825C2.34742 9.74536 3.62784 10.7768 5.15722 10.7768C3.94794 11.7015 2.45412 12.2706 0.818041 12.2706C0.533505 12.2706 0.248969 12.2706 0 12.2351C1.56495 13.2309 3.37887 13.8 5.37062 13.8C11.8082 13.8 15.3294 8.46495 15.3294 3.84124C15.3294 3.69897 15.3294 3.52113 15.3294 3.37887C16.0052 2.9165 16.6098 2.31186 17.0722 1.60052Z"
                    fill="#3FD1FF"
                  />
                </svg>
              </span>
            </div>
          </div> */}
                    </div>
                </div>
            </div>

            <div className="product-des-wrapper w-full relative pb-[60px]">
                <div className="tab-contents w-full min-h-[400px] ">
                    <div className="container-x mx-auto">
                        {tab === 'review' && (
                            <div
                                data-aos="fade-up"
                                className="w-full tab-content-item"
                            >
                                <h6 className="text-[18px] font-medium text-qblack mb-2">
                                    Reviews
                                </h6>

                                <div className="w-full">
                                    <div className="review-wrapper w-full">
                                        <div className="w-full reviews mb-[60px]">
                                            {/* comments */}
                                            <div className="w-full comments mb-[60px]">
                                                {reviews &&
                                                    reviews.length > 0 &&
                                                    reviews.map((comment) => (
                                                        <div
                                                            key={comment.id}
                                                            className="comment-item bg-white px-10 py-[32px] mb-2.5"
                                                        >
                                                            <div className="comment-author flex justify-between items-center mb-3">
                                                                <div className="flex space-x-3 items-center">
                                                                    <div>
                                                                        <p className="text-[18px] font-medium text-qblack">
                                                                            {comment
                                                                                .user
                                                                                ?.first_name +
                                                                                ' ' +
                                                                                comment
                                                                                    .user
                                                                                    ?.last_name}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="flex items-center space-x-2">
                                                                    <div className="flex">
                                                                        {starRating.map(
                                                                            (
                                                                                item
                                                                            ) => {
                                                                                return (
                                                                                    <span
                                                                                        className={
                                                                                            item ==
                                                                                            0
                                                                                                ? 'text-qgray'
                                                                                                : 'text-qyellow'
                                                                                        }
                                                                                    >
                                                                                        <svg
                                                                                            width="19"
                                                                                            height="18"
                                                                                            viewBox="0 0 19 18"
                                                                                            fill="none"
                                                                                            className="fill-current"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <path d="M9.5 0L11.6329 6.56434H18.535L12.9511 10.6213L15.084 17.1857L9.5 13.1287L3.91604 17.1857L6.04892 10.6213L0.464963 6.56434H7.36712L9.5 0Z" />
                                                                                        </svg>
                                                                                    </span>
                                                                                )
                                                                            }
                                                                        )}
                                                                    </div>
                                                                    <span className="text-[13px] font-normal text-qblack mt-1 inline-block">
                                                                        (
                                                                        {
                                                                            comment.avg_rating
                                                                        }
                                                                        )
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="comment mb-[30px]">
                                                                <div className="rating">
                                                                    <div className="rating-label">
                                                                        Product
                                                                    </div>
                                                                    <div className="flex flex-row">
                                                                        {[
                                                                            0,
                                                                            0,
                                                                            0,
                                                                            0,
                                                                            0,
                                                                        ]
                                                                            .map(
                                                                                (
                                                                                    v,
                                                                                    i
                                                                                ) =>
                                                                                    i <
                                                                                    Math.floor(
                                                                                        comment.rating
                                                                                    )
                                                                                        ? 1
                                                                                        : 0
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    item
                                                                                ) => {
                                                                                    return (
                                                                                        <span
                                                                                            className={
                                                                                                item ==
                                                                                                0
                                                                                                    ? 'text-qgray'
                                                                                                    : 'text-qyellow'
                                                                                            }
                                                                                        >
                                                                                            <svg
                                                                                                width="19"
                                                                                                height="18"
                                                                                                viewBox="0 0 19 18"
                                                                                                fill="none"
                                                                                                className="fill-current"
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                            >
                                                                                                <path d="M9.5 0L11.6329 6.56434H18.535L12.9511 10.6213L15.084 17.1857L9.5 13.1287L3.91604 17.1857L6.04892 10.6213L0.464963 6.56434H7.36712L9.5 0Z" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    )
                                                                                }
                                                                            )}
                                                                    </div>
                                                                    <div className="font-normal ml-4 text-qblack ">
                                                                        {
                                                                            comment.rating
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="rating">
                                                                    <div className="rating-label">
                                                                        Service
                                                                    </div>
                                                                    <div className="flex flex-row">
                                                                        {[
                                                                            0,
                                                                            0,
                                                                            0,
                                                                            0,
                                                                            0,
                                                                        ]
                                                                            .map(
                                                                                (
                                                                                    v,
                                                                                    i
                                                                                ) =>
                                                                                    i <
                                                                                    Math.floor(
                                                                                        comment.store_service
                                                                                    )
                                                                                        ? 1
                                                                                        : 0
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    item
                                                                                ) => {
                                                                                    return (
                                                                                        <span
                                                                                            className={
                                                                                                item ==
                                                                                                0
                                                                                                    ? 'text-qgray'
                                                                                                    : 'text-qyellow'
                                                                                            }
                                                                                        >
                                                                                            <svg
                                                                                                width="19"
                                                                                                height="18"
                                                                                                viewBox="0 0 19 18"
                                                                                                fill="none"
                                                                                                className="fill-current"
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                            >
                                                                                                <path d="M9.5 0L11.6329 6.56434H18.535L12.9511 10.6213L15.084 17.1857L9.5 13.1287L3.91604 17.1857L6.04892 10.6213L0.464963 6.56434H7.36712L9.5 0Z" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    )
                                                                                }
                                                                            )}
                                                                    </div>
                                                                    <div className="font-normal ml-4 text-qblack ">
                                                                        {
                                                                            comment.store_service
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="rating">
                                                                    <div className="rating-label">
                                                                        Shipping
                                                                    </div>
                                                                    <div className="flex flex-row">
                                                                        {[
                                                                            0,
                                                                            0,
                                                                            0,
                                                                            0,
                                                                            0,
                                                                        ]
                                                                            .map(
                                                                                (
                                                                                    v,
                                                                                    i
                                                                                ) =>
                                                                                    i <
                                                                                    Math.floor(
                                                                                        comment.store_ship
                                                                                    )
                                                                                        ? 1
                                                                                        : 0
                                                                            )
                                                                            .map(
                                                                                (
                                                                                    item
                                                                                ) => {
                                                                                    return (
                                                                                        <span
                                                                                            className={
                                                                                                item ==
                                                                                                0
                                                                                                    ? 'text-qgray'
                                                                                                    : 'text-qyellow'
                                                                                            }
                                                                                        >
                                                                                            <svg
                                                                                                width="19"
                                                                                                height="18"
                                                                                                viewBox="0 0 19 18"
                                                                                                fill="none"
                                                                                                className="fill-current"
                                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                            >
                                                                                                <path d="M9.5 0L11.6329 6.56434H18.535L12.9511 10.6213L15.084 17.1857L9.5 13.1287L3.91604 17.1857L6.04892 10.6213L0.464963 6.56434H7.36712L9.5 0Z" />
                                                                                            </svg>
                                                                                        </span>
                                                                                    )
                                                                                }
                                                                            )}
                                                                    </div>
                                                                    <div className="font-normal ml-4 text-qblack ">
                                                                        {
                                                                            comment.store_ship
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <p className="text-[15px] text-qgray leading-7 text-normal">
                                                                    {
                                                                        comment.comments
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                            {/* load comments */}
                                            {/* <div className="w-full flex justify-center">
												<button
													type="button"
													className="black-btn w-[300px] h-[50px] text-sm font-semibold"
												>
													Load More
												</button>
											</div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
