import { useNavigate } from 'react-router-dom'
import InputCom from '../../..//Helpers/InputCom'
import PageTitle from '../../../Helpers/PageTitle'
import LayoutHomeTwo from '../../../Partials/LayoutHomeTwo'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BASE_URL } from '../../../../utils/response'
export default function OrderTab() {
    const [orders, setOrders] = useState([])
    // console.log(orders);
    const navigate = useNavigate()

    const shopUserData = useSelector((state) => state.shopUserData.shopUserData)

    const viewDetails = (item) => {
        navigate(
            `/order/${
                'order_' +
                window.btoa(
                    item.order_date + item.order_status + item.order_number
                )
            }`,
            { state: { order: item } }
        )
    }

    useEffect(() => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + shopUserData.token,
        }

        axios
            .get(`${BASE_URL}/user/orders`, {
                headers: headers,
            })
            .then((response) => {
                let orderList = response.data.data.records.map((item) => {
                    // console.log(item);
                    let d = new Date(item.updated_at)

                    let date =
                        String(d.getMonth() + 1).padStart(2, '0') +
                        '-' +
                        String(d.getDate()).padStart(2, '0') +
                        '-' +
                        d.getFullYear()

                    return {
                        order_id: item.id,
                        order_number: item.order_number,
                        order_status: item.order_status,
                        final_amount: Number(
                            +item.total_amount + +item.shipping_amount_total
                        ).toFixed(2),
                        total_amount: Number(item.total_amount).toFixed(2),
                        order_date: date,
                        name: item.first_name + ' ' + item.last_name,
                        email: item.email,
                        billing_address: item.billing_street_address,
                        billing_zip: item.billing_zip,
                        shipping_address: item.shipping_street_address,
                        shipping_zip: item.shipping_zip,
                        shipping_amount_total: item.shipping_amount_total,
                        order_details: item.order_details.map((detail) => {
                            return {
                                image:
                                    detail.product.ImgURL +
                                    detail.product.thumbnail,
                                slug: detail.product.slug,
                                color: detail.color_title,
                                size: detail.measurement_size,
                                quantity: detail.quantity,
                                price: detail.price,
                                title: detail.product.title,
                                trackingId: detail?.tracking_id,
                            }
                        }),
                    }
                })
                setOrders(orderList)
            })
    }, [])

    return (
        <div className="tracking-page-wrapper w-full">
            <div className="content-wrapper w-full mb-[40px]">
                <div className="container-x mx-auto">
                    <div>
                        <div className="relative w-full overflow-x-auto border border-[#EDEDED]">
                            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                                <tbody>
                                    {/* table heading */}
                                    <tr className="text-[13px] font-medium text-black bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom uppercase">
                                        <td className="py-4 pl-10 block whitespace-nowrap min-w-1/5">
                                            Order Number
                                        </td>
                                        <td className="py-4 pl-10 whitespace-nowrap min-w-1/5">
                                            Order Date
                                        </td>
                                        <td className="py-4 whitespace-nowrap min-w-1/5">
                                            Status
                                        </td>
                                        <td className="text-right py-4 pr-10 whitespace-nowrap min-w-1/5">
                                            Total Amount
                                        </td>

                                        <td className="py-4 whitespace-nowrap min-w-1/5">
                                            Order Details
                                        </td>
                                    </tr>
                                    {/* table heading end */}
                                    {orders.map((item) => (
                                        <tr
                                            key={item.order_id}
                                            className="bg-white border-b hover:bg-gray-50"
                                        >
                                            <td className="pl-10  py-4  min-w-1/5">
                                                <div className="flex space-x-6 items-center">
                                                    <div className="flex-1 flex flex-col">
                                                        <p className="font-medium text-[15px] text-qblack">
                                                            {item.order_number}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="pl-10 py-4 ">
                                                <div className="flex space-x-6 items-center">
                                                    <div className="flex-1 flex flex-col">
                                                        <p className="font-medium text-[15px] text-qblack">
                                                            {item.order_date}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="  py-4">
                                                <div className="flex space-x-6 items-center">
                                                    <div className="flex-1 flex flex-col">
                                                        <p className="font-medium text-[15px] text-qblack">
                                                            {item.order_status}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="text-right pr-10  py-4 ">
                                                <div className="flex space-x-6 items-center">
                                                    <div className="flex-1 flex flex-col">
                                                        <p className="font-medium text-[15px] text-qblack">
                                                            {item.final_amount}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>

                                            <td className="  py-4 ">
                                                {/* <div className="flex space-x-6 items-center">
														<div className="flex-1 flex flex-col">
															<p className="font-medium text-[15px] text-qblack">
																{item.order_details &&
																	item.order_details.map((item) => {
																		return (
																			<div>
																				<div style={{ fontSize: "0.9rem" }}>
																					{item.title}
																				</div>
																				<div style={{ fontSize: "0.7rem" }}>
																					{item.color} - {item.size} -{" "}
																					{(+item.quantity).toFixed(0)}
																				</div>
																			</div>
																		);
																	})}
															</p>
														</div>
													</div> */}
                                                <button
                                                    onClick={() => {
                                                        viewDetails(item)
                                                    }}
                                                >
                                                    View Details
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
