// src/store.js
import { createStore, applyMiddleware } from 'redux'
import rootReducer from './reducers'
import thunk from 'redux-thunk'

const loadStateFromLocalStorage = () => {
    try {
        const serializedState = localStorage.getItem('state')
        if (serializedState === null) {
            return undefined
        }
        return JSON.parse(serializedState)
    } catch (error) {
        return undefined
    }
}

const saveStateToLocalStorage = (state) => {
    try {
        const serializedState = JSON.stringify(state)
        localStorage.setItem('state', serializedState)
    } catch (error) {
        console.error('Error saving state to localStorage:', error)
    }
}

const persistedState = loadStateFromLocalStorage()

const store = createStore(rootReducer, persistedState, applyMiddleware(thunk))

store.subscribe(() => {
    saveStateToLocalStorage(store.getState())
})

export default store
