import Cart from '../../../Cart'
import Compair from '../../../Helpers/icons/Compair'
import ThinBag from '../../../Helpers/icons/ThinBag'
import ThinLove from '../../../Helpers/icons/ThinLove'
import ThinPeople from '../../../Helpers/icons/ThinPeople'
import SearchBox from '../../../Helpers/SearchBox'
import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default function Middlebar({ className }) {
    // const [toggleCart, setToggle] = useState(false);
    // const cartHandler = () => {
    //   setToggle(!toggleCart);
    // };
    const cart = useSelector((state) => state.cart)

    const wishlist = useSelector((state) => state.wishlist)

    return (
        <div className={`w-full h-[86px] bg-white ${className}`}>
            <div className="container-x mx-auto h-full">
                <div className="relative h-full">
                    <div className="flex justify-between items-center h-full">
                        <div>
                            <a href="/">
                                <img
                                    width="152"
                                    height="36"
                                    src={`${process.env.PUBLIC_URL}/assets/images/BuildLinkCompleteLogo.png`}
                                    alt="logo"
                                />
                            </a>
                        </div>
                        <div className="w-[517px] h-[44px]">
                            <SearchBox className="search-com" />
                        </div>
                        <div className="flex space-x-6 items-center">
                            <div
                                style={{ display: 'none' }}
                                className="compaire relative"
                            >
                                <a href="/products-compaire">
                                    <span>
                                        <Compair />
                                    </span>
                                </a>
                                <span className="w-[18px] h-[18px] rounded-full bg-qh2-green absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] text-white">
                                    2
                                </span>
                            </div>
                            <div className="favorite relative">
                                {/* <div className="cart relative cursor-pointer">
									<span>
										<ThinLove />
									</span>
									{cart?.items?.length > 0 ? (
										<span className="w-[18px] h-[18px] rounded-full bg-qh2-green absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] text-white">
											{cart?.items?.length}
										</span>
									) : (
										""
									)}
								</div> */}
                                {/* <div className="fixed left-0 top-0 w-full h-full z-40"></div> */}
                                {/* hidden group-hover:block" */}
                                {cart?.items?.length > 0 ? (
                                    <Cart className="absolute -right-[45px] top-11 z-50 hidden group-hover:block " />
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="cart-wrapper group relative py-4">
                                <div className="cart relative cursor-pointer">
                                    <span>
                                        <ThinBag />
                                    </span>
                                    {cart?.items?.length > 0 ? (
                                        <span className="w-[18px] h-[18px] rounded-full bg-qh2-green absolute -top-2.5 -right-2.5 flex justify-center items-center text-[9px] text-white">
                                            {cart?.items?.length}
                                        </span>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                {/* <div className="fixed left-0 top-0 w-full h-full z-40"></div> */}
                                {/* hidden group-hover:block" */}
                                {cart?.items?.length > 0 ? (
                                    <Cart className="absolute -right-[45px] top-11 z-50 hidden group-hover:block " />
                                ) : (
                                    ''
                                )}
                            </div>
                            {/* <div>
                <Link to="/profile">
                  <span>
                    <ThinPeople />
                  </span>
                </Link>
              </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
