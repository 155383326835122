export default function CategoryFilter({
	className,
	filterToggle,
	subCategoryHandler,
	subCategorySelected,
	searchTextHandler,
	subcategories,
	sortHandler,
	sortSelected,
	searchText,
	onSearch,
}) {
	return (
		<>
			<div
				className={`filter-widget w-full fixed lg:relative left-0 top-0 h-screen z-10 lg:h-auto overflow-y-scroll lg:overflow-y-auto bg-white px-[30px] pt-[40px] ${
					className || ""
				}  ${filterToggle ? "block" : "hidden lg:block"}`}
			>
				<div className="filter-subject-item pb-2 border-qgray-border">
					<div className="subject-title mb-[10px]">
						<h1 className="text-black text-base font-500">Product Name</h1>
					</div>
					<div className="price-range mb-5">
						<div>
							<input
								style={{
									borderStyle: "none",
									border: "1px grey solid",
									padding: "0.2rem",
									marginBottom: "1rem",
									width: "13rem",
									height: "2.4rem",
								}}
								id={`searchText`}
								type="text"
								name="searchText"
								onChange={(e) => {
									searchTextHandler(e.target.value);
								}}
								value={searchText}
							/>
						</div>
						<button
							style={{
								backgroundColor: "#005699",
								color: "white",
								fontSize: "0.8rem",
								padding: "0.4rem 0.8rem 0.4rem 0.8rem",
							}}
							onClick={onSearch}
						>
							Search
						</button>
					</div>
				</div>
				{subcategories && subcategories.length > 0 && (
					<div className="filter-subject-item pb-10 border-qgray-border">
						<div className="subject-title mb-[30px]">
							<h1 className="text-black text-base font-500">Sub-Categories</h1>
						</div>
						<div className="filter-items">
							<ul>
								<li className="item flex justify-between items-center mb-5">
									<div className="flex space-x-[14px] items-center">
										<div>
											<input
												id={`all`}
												type="radio"
												name="subcategory"
												onClick={() => {
													subCategoryHandler("");
												}}
												value={``}
												checked={subCategorySelected === ""}
											/>
										</div>
										<div>
											<label
												htmlFor="mobileLaptop"
												className="text-xs font-black font-400 capitalize"
											>
												All
											</label>
										</div>
									</div>
								</li>
								{subcategories &&
									subcategories.length > 0 &&
									subcategories.map((item) => {
										return (
											<li
												key={item.slug}
												className="item flex justify-between items-center mb-5"
											>
												<div className="flex space-x-[14px] items-center">
													<div>
														<input
															id={item.slug}
															type="radio"
															name="subcategory"
															onClick={(e) => {
																subCategoryHandler(item.slug);
															}}
															value={item.slug}
															checked={subCategorySelected === item.slug}
														/>
													</div>
													<div>
														<label
															htmlFor="mobileLaptop"
															className="text-xs font-black font-400 capitalize"
														>
															{item.title}
														</label>
													</div>
												</div>
											</li>
										);
									})}
							</ul>
						</div>
					</div>
				)}
				{/* <div className="filter-subject-item pb-10 border-b border-qgray-border">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">
              Sort
            </h1>
          </div>
          <div className="price-range mb-5">
            <span>
              <input

                id={`order`}
                type="radio"
                name="order"
                onClick={(e) => { sortHandler(e.target.value) }}
                checked={sortSelected === "title-ASC"}
                value="title-ASC"
              />
            </span>
            <span className="text-xs text-qblack font-400 ml-2">Name: A - Z</span>
            </div>
            <div className="price-range mb-5">
            <span>
              <input

                id={`order`}
                type="radio"
                name="order"
                value="title-DESC"
                onClick={(e) => { sortHandler(e.target.value) }}
                checked={sortSelected === "title-DESC"}
              />
            </span>
            <span className="text-xs text-qblack font-400 ml-2">Name: Z - A</span>
          </div>
          <div className="price-range mb-5">
            <span >
              <input

                id={`order`}
                type="radio"
                name="order"
                onClick={(e) => { sortHandler(e.target.value) }}
                checked={sortSelected === "price-DESC"}
                value="price-DESC"
              />
            </span>
            <span className="text-xs text-qblack font-400 ml-2">Price: High to Low</span>
            </div>
            <div className="price-range mb-5">
            <span >
              <input

                id={`order`}
                type="radio"
                name="order"
                onClick={(e) => { sortHandler(e.target.value) }}
                checked={sortSelected === "price-ASC"}
                value="price-ASC"
              />
            </span>
            <span className="text-xs text-qblack font-400 ml-2">Price: Low to High</span>
          </div>
        </div> */}

				{/*<div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Brands</h1>
          </div>
          <div className="filter-items">
            <ul>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="apple"
                      name="apple"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.apple}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="apple"
                      className="text-xs font-black font-400 capitalize"
                    >
                      apple
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="samsung"
                      name="samsung"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.samsung}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="samsung"
                      className="text-xs font-black font-400 capitalize"
                    >
                      Samsung
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="walton"
                      name="walton"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.walton}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="walton"
                      className="text-xs font-black font-400 capitalize"
                    >
                      walton
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="oneplus"
                      name="oneplus"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.oneplus}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="oneplus"
                      className="text-xs font-black font-400 capitalize"
                    >
                      oneplus
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="vivo"
                      name="vivo"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.vivo}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="vivo"
                      className="text-xs font-black font-400 capitalize"
                    >
                      vivo
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="oppo"
                      name="oppo"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.oppo}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="oppo"
                      className="text-xs font-black font-400 capitalize"
                    >
                      oppo
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="xiomi"
                      name="xiomi"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.xiomi}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="xiomi"
                      className="text-xs font-black font-400 capitalize"
                    >
                      xiomi
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="others"
                      name="others"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.others}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="others"
                      className="text-xs font-black font-400 capitalize"
                    >
                      others
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="filter-subject-item pb-10 border-b border-qgray-border mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Storage</h1>
          </div>
          <div className="filter-items">
            <div className="flex space-x-[5px] flex-wrap">
              <span
                onClick={() => filterstorage("64GB")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${storage === "64GB"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                  }`}
              >
                64GB
              </span>
              <span
                onClick={() => filterstorage("128GB")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${storage === "128GB"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                  }`}
              >
                128GB
              </span>
              <span
                onClick={() => filterstorage("256GB")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${storage === "256GB"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                  }`}
              >
                256GB
              </span>
              <span
                onClick={() => filterstorage("512GB")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${storage === "512GB"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                  }`}
              >
                512GB
              </span>
              <span
                onClick={() => filterstorage("1024GB")}
                className={` font-400 border border-qgray-border text-xs px-[14px] py-[6px] cursor-pointer mb-[5px] ${storage === "1024GB"
                    ? "bg-qyellow text-qblack border-none"
                    : " text-qgray "
                  }`}
              >
                1024GB
              </span>
            </div>
          </div>
        </div>
        <div className="filter-subject-item pb-10 mt-10">
          <div className="subject-title mb-[30px]">
            <h1 className="text-black text-base font-500">Sizes</h1>
          </div>
          <div className="filter-items">
            <ul>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="sizeS"
                      name="sizeS"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.sizeS}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="sizeS"
                      className="text-xs font-black font-400 capitalize"
                    >
                      s
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="sizeM"
                      name="sizeM"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.sizeM}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="sizeM"
                      className="text-xs font-black font-400 capitalize"
                    >
                      M
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="sizeXL"
                      name="sizeXL"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.sizeXL}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="sizeXL"
                      className="text-xs font-black font-400 capitalize"
                    >
                      XL
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="sizeXXL"
                      name="sizeXXL"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.sizeXXL}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="sizeXXL"
                      className="text-xs font-black font-400 capitalize"
                    >
                      XXL
                    </label>
                  </div>
                </div>
              </li>
              <li className="item flex justify-between items-center mb-5">
                <div className="flex space-x-[14px] items-center">
                  <div>
                    <Checkbox
                      id="sizeFit"
                      name="sizeFit"
                      handleChange={(e) => checkboxHandler(e)}
                      checked={filters.sizeFit}
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="sizeFit"
                      className="text-xs font-black font-400 capitalize"
                    >
                      Sliem Fit
                    </label>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <button
          onClick={filterToggleHandler}
          type="button"
          className="w-10 h-10 fixed top-5 right-5 z-50 rounded  lg:hidden flex justify-center items-center border border-qred text-qred"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button> */}
			</div>
		</>
	);
}
