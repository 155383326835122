const initialState = {
    shopUserData: {},
}

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                ...state,
                shopUserData: { ...state.shopUserData, ...action.payload },
            }
        case 'LOGOUT':
            return {
                ...state,
                shopUserData: {},
            }
        default:
            return state
    }
}

export default profileReducer
