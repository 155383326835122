import BreadcrumbCom from '../BreadcrumbCom'
import EmptyCardError from '../EmptyCardError'
import InputCom from '../Helpers/InputCom'
import LoginModal from '../Helpers/LoginModal/LoginModal'
import PageTitle from '../Helpers/PageTitle'
import Layout from '../Partials/LayoutHomeTwo'
import ProductsTable from './ProductsTable'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AlertToast from '../AlertToast/AlertToast'
import { updateItemShippingCost } from '../../store/actions'
import axios from 'axios'
import { BASE_URL } from '../../utils/response'

export default function CardPage({ cartDisplay = true }) {
    const cart = useSelector((state) => state.cart)
    const dispatch = useDispatch()

    const [shippingCost, setShippingCost] = useState(0)
    const shopUserData = useSelector((state) => state.shopUserData)

    const calculateTotal = () => {
        return cart.items.reduce(
            (total, item) => total + item.price * item.quantity,
            0
        )
    }

    const setItemShippingCost = (item, cost) => {
        dispatch(updateItemShippingCost(item, cost))
    }

    const navigate = useNavigate()

    const [showToast, setShowToast] = useState(false)
    const [toastMessage, setToastMessage] = useState('')

    const showToastMessage = (message) => {
        setToastMessage(message)
        setShowToast(true)
        setTimeout(() => setShowToast(false), 3000) // Show for 3 seconds
    }

    const [isModalOpen, setIsModalOpen] = useState(false)

    const openModal = () => {
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    const checkout = () => {
        if (cart.items.length <= 0) {
            return
            //ADD TOAST
        }

        const serializedState = localStorage.getItem('state')
        const state = JSON.parse(serializedState)
        if (shopUserData.shopUserData?.token) {
            navigate('/checkout', { state: { shipping: shippingCost } })
        } else {
            openModal()
        }
    }

    // 	const meraFunction = () => {
    // 		let cost = 0;

    // 		const requests = [];

    // 		cart.items.forEach(async (item) => {
    // 			let postBody = {
    // 				RateRequest: {
    // 					Request: {
    // 						TransactionReference: {
    // 							CustomerContext: "CustomerContext",
    // 						},
    // 						RequestOption: "Shoptimeintransit",
    // 					},
    // 					Shipment: {
    // 						Shipper: {
    // 							Name: "ShipperName",
    // 							ShipperNumber: "F4473G",
    // 							Address: {
    // 								AddressLine: [
    // 									"ShipperAddressLine",
    // 									"ShipperAddressLine",
    // 									"ShipperAddressLine",
    // 								],
    // 								City: "TIMONIUM",
    // 								StateProvinceCode: "MD",
    // 								PostalCode: "21093",
    // 								CountryCode: "US",
    // 							},
    // 						},
    // 						ShipTo: {
    // 							Name: "ShipToName",
    // 							Address: {
    // 								AddressLine: [
    // 									"ShipToAddressLine",
    // 									"ShipToAddressLine",
    // 									"ShipToAddressLine",
    // 								],
    // 								City: "Alpharetta",
    // 								StateProvinceCode: "GA",
    // 								PostalCode: "30005",
    // 								CountryCode: "US",
    // 							},
    // 						},
    // 						ShipFrom: {
    // 							Name: "ShipFromName",
    // 							Address: {
    // 								AddressLine: [
    // 									"ShipFromAddressLine",
    // 									"ShipFromAddressLine",
    // 									"ShipFromAddressLine",
    // 								],
    // 								City: "TIMONIUM",
    // 								StateProvinceCode: "MD",
    // 								PostalCode: "21093",
    // 								CountryCode: "US",
    // 							},
    // 						},
    // 						PaymentDetails: {
    // 							ShipmentCharge: {
    // 								Type: "01",
    // 								BillShipper: {
    // 									AccountNumber: "F4473G",
    // 								},
    // 							},
    // 						},
    // 						Service: {
    // 							Code: "03",
    // 							Description: "Ground",
    // 						},
    // 						NumOfPieces: item.quantity,
    // 						Package: {
    // 							PackagingType: {
    // 								Code: "02",
    // 								Description: "Packaging",
    // 							},
    // 							Dimensions: {
    // 								UnitOfMeasurement: {
    // 									Code: "IN",
    // 									Description: "Inches",
    // 								},
    // 								Length: (+item.size.length).toFixed(2),
    // 								Width: (+item.size.width).toFixed(2),
    // 								Height: (+item.size.height).toFixed(2),
    // 							},
    // 							PackageWeight: {
    // 								UnitOfMeasurement: {
    // 									Code: "LBS",
    // 									Description: "Pounds",
    // 								},
    // 								Weight: (+item.size.weight).toFixed(2),
    // 							},
    // 						},
    // 					},
    // 				},
    // 			};

    // 			let body = {
    // 				path_variable: "rating/v1/rate",
    // 				query_parameters: "",
    // 				headers: {
    // 					transId: "string",
    // 					transactionSrc: "testing",
    // 					"Content-Type": "application/json",
    // 				},
    // 				body: postBody,
    // 			};

    // 			if (cart.items.length > 0) {
    // 				const request = await axios.post(
    // 					//`${BASE_URL}/UPS_proxy.php`,
    // 					`${BASE_URL}/ups-proxy`,
    // 					{ ...body }
    // 				);
    // console.log(request)
    // 				requests.push(request);
    // 			}
    // 		});
    // 		console.log(requests)

    // 		Promise.all(requests)
    // 			.then((results) => {
    // 				console.log(results);
    // 				const aggregatedData = results.forEach((item, index) => {
    // 					setItemShippingCost(
    // 						cart.items[index],
    // 						+item.data.data.RateResponse.RatedShipment.TotalCharges
    // 							.MonetaryValue
    // 					);

    // 					cost =
    // 						cost +
    // 						+item.data.data.RateResponse.RatedShipment.TotalCharges
    // 							.MonetaryValue;
    // 				});

    // 				// Update your variable with the aggregated data
    // 				setShippingCost(+cost.toFixed(2));
    // // console.log(+cost.toFixed(2));
    // 				// Continue with any further processing
    // 			})

    // 			.catch((error) => {
    // 				// Handle errors from any of the requests
    // 				console.error("One or more requests failed:", error);
    // 			});
    // 			console.log(shippingCost);
    // 	}

    const meraFunction = async () => {
        let cost = 0
        const requests = []

        // Use a normal loop to handle async/await properly
        for (const item of cart.items) {
            let postBody = {
                RateRequest: {
                    Request: {
                        TransactionReference: {
                            CustomerContext: 'CustomerContext',
                        },
                        RequestOption: 'Shoptimeintransit',
                    },
                    Shipment: {
                        Shipper: {
                            Name: 'ShipperName',
                            ShipperNumber: 'F4473G',
                            Address: {
                                AddressLine: [
                                    'ShipperAddressLine',
                                    'ShipperAddressLine',
                                    'ShipperAddressLine',
                                ],
                                City: 'TIMONIUM',
                                StateProvinceCode: 'MD',
                                PostalCode: '21093',
                                CountryCode: 'US',
                            },
                        },
                        ShipTo: {
                            Name: 'ShipToName',
                            Address: {
                                AddressLine: [
                                    'ShipToAddressLine',
                                    'ShipToAddressLine',
                                    'ShipToAddressLine',
                                ],
                                City: 'Alpharetta',
                                StateProvinceCode: 'GA',
                                PostalCode: '30005',
                                CountryCode: 'US',
                            },
                        },
                        ShipFrom: {
                            Name: 'ShipFromName',
                            Address: {
                                AddressLine: [
                                    'ShipFromAddressLine',
                                    'ShipFromAddressLine',
                                    'ShipFromAddressLine',
                                ],
                                City: 'TIMONIUM',
                                StateProvinceCode: 'MD',
                                PostalCode: '21093',
                                CountryCode: 'US',
                            },
                        },
                        PaymentDetails: {
                            ShipmentCharge: {
                                Type: '01',
                                BillShipper: {
                                    AccountNumber: 'F4473G',
                                },
                            },
                        },
                        Service: {
                            Code: '03',
                            Description: 'Ground',
                        },
                        NumOfPieces: item.quantity,
                        Package: {
                            PackagingType: {
                                Code: '02',
                                Description: 'Packaging',
                            },
                            Dimensions: {
                                UnitOfMeasurement: {
                                    Code: 'IN',
                                    Description: 'Inches',
                                },
                                Length: (+item.size.length).toFixed(2),
                                Width: (+item.size.width).toFixed(2),
                                Height: (+item.size.height).toFixed(2),
                            },
                            PackageWeight: {
                                UnitOfMeasurement: {
                                    Code: 'LBS',
                                    Description: 'Pounds',
                                },
                                Weight: (+item.size.weight).toFixed(2),
                            },
                        },
                    },
                },
            }

            let body = {
                path_variable: 'rating/v1/rate',
                query_parameters: '',
                headers: {
                    transId: 'string',
                    transactionSrc: 'testing',
                    'Content-Type': 'application/json',
                },
                body: postBody,
            }

            // Push the request promises into the array
            requests.push(axios.post(`${BASE_URL}/ups-proxy`, { ...body }))
        }

        try {
            // Await all requests to complete
            const results = await Promise.all(requests)

            // Process the results
            results.forEach((item, index) => {
                const rateResponse =
                    item.data.data.RateResponse.RatedShipment.TotalCharges
                setItemShippingCost(
                    cart.items[index],
                    +rateResponse.MonetaryValue
                )

                cost += +rateResponse.MonetaryValue
            })

            // Update shipping cost
            setShippingCost(+cost.toFixed(2))
        } catch (error) {
            // Handle errors
            console.error('One or more requests failed:', error)
        }

        // Optionally log the shipping cost after processing
        console.log(shippingCost)
    }

    useEffect(() => {
        meraFunction()
    }, [])

    return (
        <Layout childrenClasses={cartDisplay ? 'pt-0 pb-0' : ''}>
            {cartDisplay === false ? (
                <div className="cartDisplay-page-wrapper w-full">
                    <div className="container-x mx-auto">
                        <EmptyCardError />
                    </div>
                </div>
            ) : (
                <div className="cartDisplay-page-wrapper w-full  pb-[60px]">
                    <div className="w-full">
                        <PageTitle title="Your Cart" />
                    </div>
                    <div class="grid grid-cols-3 gap-4">
                        <div className="col-span-2">
                            <div className="container-x">
                                <ProductsTable className="mb-[30px]" />

                                <div className="w-full sm:flex justify-between">
                                    <div className="discount-code sm:w-[270px] w-full mb-5 sm:mb-0 h-[50px] flex">
                                        {/* <div className="flex-1 h-full">
                    <InputCom type="text" placeholder="Discount Code" />
                  </div>
                  <button type="button" className="w-[90px] h-[50px] black-btn">
                    <span className="text-sm font-semibold">Apply</span>
                  </button> */}
                                    </div>
                                    <div className="flex space-x-2.5 items-center">
                                        <a href="/">
                                            <div className="w-[220px] h-[50px] bg-[#F6F6F6] flex justify-center items-center">
                                                <span className="text-sm font-semibold">
                                                    Continue Shopping
                                                </span>
                                            </div>
                                        </a>
                                        {/* <a href="#">
                    <div className="w-[140px] h-[50px] bg-[#F6F6F6] flex justify-center items-center">
                      <span className="text-sm font-semibold">Update cartDisplay</span>
                    </div>
                  </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1">
                            <div className="px-10 py-[30px] border border-[#EDEDED] bg-white">
                                <div className="mt-[30px]">
                                    <div className=" flex justify-between mb-5">
                                        <p className="text-[13px] font-medium text-qblack uppercase">
                                            SUBTOTAL
                                        </p>
                                        <p className="text-[15px] font-medium text-qblack uppercase">
                                            ${calculateTotal()}
                                        </p>
                                    </div>
                                </div>

                                <div className="w-full mt-[30px]">
                                    <div className="sub-total mb-6">
                                        <div className=" flex justify-between mb-5">
                                            <div>
                                                <span className="text-xs text-qgraytwo mb-3 block">
                                                    SHIPPING
                                                </span>
                                                {/* <p className="text-base font-medium text-qblack">
                            Free Shipping
                          </p> */}
                                            </div>
                                            <p className="text-[15px] font-medium text-qblack">
                                                ${shippingCost}
                                            </p>
                                        </div>
                                        <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                                    </div>
                                </div>

                                <div className="mt-[30px]">
                                    <div className=" flex justify-between mb-5">
                                        <p className="text-2xl font-medium text-qblack">
                                            Total
                                        </p>
                                        <p className="text-2xl font-medium text-qred">
                                            $
                                            {(
                                                calculateTotal() + +shippingCost
                                            ).toFixed(2)}
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="w-full h-[50px] black-btn flex justify-center items-center"
                                    onClick={checkout}
                                >
                                    Proceed to Checkout
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <LoginModal isOpen={isModalOpen} onClose={closeModal}></LoginModal>
        </Layout>
    )
}
