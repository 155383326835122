export const addToCart = (item) => ({
    type: 'ADD_TO_CART',
    payload: item,
})

export const removeFromCart = (item) => ({
    type: 'REMOVE_FROM_CART',
    payload: item,
})

export const addToWishlist = (item) => ({
    type: 'ADD_TO_WISHLIST',
    payload: item,
})

export const removeFromWishlist = (item) => ({
    type: 'REMOVE_FROM_WISHLIST',
    payload: item,
})

export const incrementItemQuantity = (item) => ({
    type: 'INCREMENT_ITEM_QUANTITY',
    payload: item,
})

export const decrementItemQuantity = (item) => ({
    type: 'DECREMENT_ITEM_QUANTITY',
    payload: item,
})

export const updateItemShippingCost = (item, shippingCost) => ({
    type: 'UPDATE_ITEM_SHIPPING_COST',
    payload: { shippingItem: item, shippingCost: shippingCost },
})

export const login = (item) => ({
    type: 'LOGIN',
    payload: item,
})

export const setSearchQuery = (query) => ({
    type: 'SET_SEARCH_QUERY',
    payload: query,
})

export const showLoader = () => ({
    type: 'SHOW',
})

export const hideLoader = () => ({
    type: 'HIDE',
})

export const showToast = (message, type, duration) => ({
    type: 'TOAST_SHOW',
    payload: {
        show: true,
        message: message,
        type: type,
        duration: duration,
    },
})

export const hideToast = () => ({
    type: 'TOAST_HIDE',
    payload: {
        show: false,
        message: '',
        type: '',
        duration: 0,
    },
})
