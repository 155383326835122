import axios from "axios";

export const BASE_URL = process.env.REACT_APP_BASE_URL + "/api";
export const BASE_OPEN_URL = process.env.REACT_APP_BASE_URL;
export const UPLOADS_URL =
	process.env.REACT_APP_BASE_URL + "/uploads/projects/";
export const IMG_URL = process.env.REACT_APP_BASE_URL + "/uploads/portfolios";
export const Symbol_URL = process.env.REACT_APP_BASE_URL + "/uploads/symbols/";
export const PORTAL_URL = process.env.REACT_APP_PORTAL_URL;

const getHeader = () => {
	return {};
};

export const getMethodCustomHeader = (relativesUrl) => {
	return new Promise((resolve, reject) => {
		axios
			.get(`${BASE_OPEN_URL}/${relativesUrl}`, { headers: getHeader() })
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};
