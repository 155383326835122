import BreadcrumbCom from '../BreadcrumbCom'
import Layout from '../Partials/LayoutHomeTwo'
import ErrorThumb from './ErrorThumb'
import { useNavigate } from 'react-router-dom'

export default function PurchaseThankyou() {
    const navigate = useNavigate()

    const goToHome = () => {
        navigate('/')
    }

    return (
        <Layout>
            <div className="cart-page-wrapper w-full">
                <div className="container-x mx-auto">
                    <div
                        style={{
                            backgroundImage: `url('/assets/images/banner-5.1.png')`,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            height: '500px',
                            weight: '1200px',
                            marginTop: '20px',
                            backgroundPosition: 'center',
                        }}
                        className="empty-card-wrapper w-full"
                    >
                        <div className="flex justify-between items-center w-full h-full ml-10">
                            <div>
                                <div className="w-full">
                                    <h1 className="sm:text-xl text-base font-semibold text-center mb-5 ">
                                        Thank you for your purchase
                                    </h1>
                                    <div className="flex justify-center w-full mt-5">
                                        <div className="w-[180px] h-[50px] ">
                                            <span
                                                type="button"
                                                className="blue-btn"
                                                onClick={goToHome}
                                            >
                                                Back to Shop
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
