import React from 'react'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoader, showLoader } from '../../store/actions'
import Layout from '../Partials/LayoutHomeTwo'
import InputCom from '../Helpers/InputCom'
import StarRating from '../Helpers/StarRating'
import './ReviewDialog.css'
import { Link } from 'react-router-dom'
import { BASE_URL } from '../../utils/response'

import { useLocation, useParams, useNavigate } from 'react-router-dom'

export default function ReviewDialog({ className, products }) {
    const shopUserData = useSelector((state) => state.shopUserData.shopUserData)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const item = location.state

    console.log(item)
    const { orderno, productslug } = useParams()

    const [form, setForm] = useState({
        rating: 0,
        comments: '',
        store_ship: '',
        store_service: '',
        order_id: orderno,
    })

    const handleFormChange = (e) => {
        const { name, value } = e.target

        setForm({ ...form, [name]: value })
    }

    const ratingHandler = (e) => {
        setForm({ ...form, rating: e })
    }

    const serviceHandler = (e) => {
        setForm({ ...form, store_service: e })
    }

    const shipHandler = (e) => {
        setForm({ ...form, store_ship: e })
    }

    const hoverHandler = (e) => {}

    const addReview = () => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + shopUserData.token,
        }
        dispatch(showLoader())
        axios
            .post(`${BASE_URL}/user/products/${productslug}/review`, form, {
                headers: headers,
            })
            .then((response) => {
                navigate('/profile#review')
            })
            .catch((e) => console.log(e))
            .finally(() => {
                dispatch(hideLoader())
            })
    }

    return (
        <Layout childrenClasses="pt-0 pb-0">
            <div className="profile-page-wrapper w-full">
                <div className="container-x mx-auto">
                    <div className="w-full my-10">
                        <div className="w-full bg-white px-10 py-9">
                            <div className="title-area w-full flex justify-between items-center">
                                <h1 className="text-[22px] font-bold text-qblack">
                                    Submit Review
                                </h1>
                            </div>
                            <div className="review-tab-wrapper w-full">
                                <div className="product-section">
                                    <table className="w-full text-sm text-left text-gray-500 ">
                                        <tbody>
                                            <tr className="bg-white  hover:bg-gray-50">
                                                <td className="pl-10  py-4  w-[380px]">
                                                    <div className="flex space-x-6 items-center">
                                                        <div className="w-[80px] h-[80px] overflow-hidden flex justify-center items-center border border-[#EDEDED]">
                                                            <img
                                                                src={
                                                                    item.product
                                                                        .ImgURL +
                                                                    item.product
                                                                        .thumbnail
                                                                }
                                                                alt="product"
                                                                className="w-full h-full object-contain"
                                                            />
                                                        </div>
                                                        <div className="flex-1 flex flex-col">
                                                            <p className="font-medium text-[15px] text-qblack">
                                                                <Link
                                                                    to={{
                                                                        pathname: `/product/${item.product.slug}`,
                                                                    }}
                                                                    state={{
                                                                        product:
                                                                            item.product,
                                                                    }}
                                                                >
                                                                    {
                                                                        item
                                                                            .product
                                                                            .title
                                                                    }
                                                                </Link>
                                                            </p>
                                                            <div
                                                                style={{
                                                                    fontSize:
                                                                        '0.7rem',
                                                                }}
                                                            >
                                                                {
                                                                    item.product
                                                                        .description
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="review-section">
                                    <div className="rating">
                                        <div className="rating-label">
                                            Product
                                        </div>
                                        <StarRating
                                            rating={form.rating}
                                            ratingHandler={ratingHandler}
                                            hoverRating={form.rating}
                                            hoverHandler={hoverHandler}
                                        />
                                    </div>
                                    <div className="rating">
                                        <div className="rating-label">
                                            Service
                                        </div>
                                        <StarRating
                                            rating={form.store_service}
                                            ratingHandler={serviceHandler}
                                            hoverRating={form.store_service}
                                            hoverHandler={hoverHandler}
                                        />
                                    </div>
                                    <div className="rating">
                                        <div className="rating-label">
                                            Shipping
                                        </div>
                                        <StarRating
                                            rating={form.store_ship}
                                            ratingHandler={shipHandler}
                                            hoverRating={form.store_ship}
                                            hoverHandler={hoverHandler}
                                        />
                                    </div>
                                    <div className="mb-5">
                                        <div className="rating-label">
                                            Comments
                                        </div>
                                        <textarea
                                            className="w-full h-40 px-2 py-2 border"
                                            placeholder="..."
                                            name="comments"
                                            type="text"
                                            value={form.comments}
                                            onChange={handleFormChange}
                                        />
                                    </div>
                                    <button
                                        className="black-btn px-8 py-4"
                                        onClick={addReview}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
