import { useEffect, useState } from 'react'
import InputCom from '../../../Helpers/InputCom'
import { useDispatch, useSelector } from 'react-redux'
import './profile.css'
import axios from 'axios'
import { showLoader, hideLoader, showToast } from '../../../../store/actions'

export default function ProfileTab() {
    const dispatch = useDispatch()

    const [profileForm, setProfileForm] = useState({
        first_name: '',
        last_name: '',
    })
    const shopUserData = useSelector((state) => state.shopUserData.shopUserData)
    const headers = {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + shopUserData.token,
    }
    useEffect(() => {
        dispatch(showLoader())
        axios
            .get('https://bladmin.buildlink.com/api/user', {
                headers: headers,
            })
            .then((resp) => {
                setProfileForm({
                    first_name: resp?.data?.data[0]?.first_name,
                    last_name: resp?.data?.data[0]?.last_name,
                })
            })
            .catch((error) => {
                dispatch(showToast(error?.data?.message, 'error', 2000))
            })
            .finally(() => {
                dispatch(hideLoader())
            })
    }, [])

    const handleProfileFormChange = (event) => {
        let form = {
            ...profileForm,
            [event.target.name]: event.target.value,
        }

        setProfileForm(form)
    }

    const updateProfile = () => {
        dispatch(showLoader())
        axios
            .post(
                'https://bladmin.buildlink.com/api/user/update',
                profileForm,
                {
                    headers: headers,
                }
            )
            .then((resp) => {
                dispatch(showToast(resp.data.message, 'success', 2000))
            })
            .catch((error) => {
                dispatch(showToast(resp.data.message, 'error', 2000))
            })
            .finally(() => {
                dispatch(hideLoader())
            })
    }

    return (
        <>
            <div className="flex mb-2">
                <div className="w-1/2 mr-2">
                    <label className="flex mb-2">First Name</label>
                    <div className="input-wrapper border border-[#E8E8E8]   h-[58px] overflow-hidden relative ">
                        <input
                            placeholder="First Name"
                            value={profileForm.first_name}
                            className="input-field placeholder:text-base text-bese px-4 text-dark-gray w-full h-full bg-[#FFFFFF] focus:ring-0 focus:outline-none"
                            id="first_name"
                            name="first_name"
                            onChange={handleProfileFormChange}
                        />
                    </div>
                </div>

                <div className="w-1/2 ml-2 ">
                    <label className="flex mb-2">Last Name</label>
                    <div className="input-wrapper border border-[#E8E8E8]  h-[58px] overflow-hidden relative ">
                        <input
                            placeholder="First Name"
                            value={profileForm.last_name}
                            className="input-field placeholder:text-base text-bese px-4 text-dark-gray w-full h-full bg-[#FFFFFF] focus:ring-0 focus:outline-none"
                            id="last_name"
                            name="last_name"
                            onChange={handleProfileFormChange}
                        />
                    </div>
                </div>
            </div>

            {/* <div className="flex mb-2">
				<div className="w-full">
					<label className="flex mb-2">Email</label>
					<div className="input-wrapper border border-[#E8E8E8] w-full  h-[58px] overflow-hidden relative ">
						<input
							placeholder="First Name"
							value={shopUserData.shopUserData.email}
							className="input-field placeholder:text-base text-bese px-4 text-dark-gray w-full h-full bg-[#FFFFFF] focus:ring-0 focus:outline-none"
							id="email"
							name="email"
						/>
					</div>
				</div>
			</div> */}

            {/* <div className="flex mb-2">
				<div className="w-1/2 mr-2">
					<label className="flex mb-2">Zip Code</label>
					<div className="input-wrapper border border-[#E8E8E8] h-[58px] overflow-hidden relative ">
						<input
							placeholder="Zip Code"
							value={shopUserData.shopUserData.zip}
							className="input-field placeholder:text-base text-bese px-4 text-dark-gray w-full h-full bg-[#FFFFFF] focus:ring-0 focus:outline-none"
							id="zip"
							name="zip"
						/>
					</div>
				</div>

				<div className="w-1/2 ml-2">
					<label className="flex mb-2">Phone No.</label>
					<div className="input-wrapper border border-[#E8E8E8]  h-[58px] overflow-hidden relative ">
						<input
							placeholder="Phone No."
							value={shopUserData.shopUserData.phone}
							className="input-field placeholder:text-base text-bese px-4 text-dark-gray w-full h-full bg-[#FFFFFF] focus:ring-0 focus:outline-none"
							id="phone"
							name="phone"
						/>
					</div>
				</div>
			</div>

			<div className="flex mb-2">
				<div className="w-full">
					<label className="flex mb-2">Street Address</label>
					<div className="input-wrapper border border-[#E8E8E8]   h-[58px] overflow-hidden relative ">
						<input
							placeholder="First Name"
							value={shopUserData.shopUserData.street_address}
							className="input-field placeholder:text-base text-bese px-4 text-dark-gray w-full h-full bg-[#FFFFFF] focus:ring-0 focus:outline-none"
							id="street_address"
							name="street_address"
						/>
					</div>
				</div>
			</div> */}

            <div className="w-full mt-[30px] flex sm:justify-end justify-start">
                <div className="sm:flex sm:space-x-[30px] items-center">
                    <div className="w-[180px] h-[50px]">
                        <button
                            type="button"
                            className="blue-btn"
                            onClick={updateProfile}
                        >
                            <div className="w-full text-sm font-semibold">
                                Update
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
