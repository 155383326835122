import InputCom from '../Helpers/InputCom'
import PageTitle from '../Helpers/PageTitle'
import Layout from '../Partials/LayoutHomeTwo'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'
import LoaderStyleOne from '../Helpers/Loaders/LoaderStyleOne'
import './checkout.css'

import { useEffect, useState } from 'react'
import { showLoader, hideLoader } from '../../store/actions'
import { BASE_URL } from '../../utils/response'
import ShippingCmp from './ShippingCmp'
export default function CheakoutPage() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const cart = useSelector((state) => state.cart)
    // console.log(cart.items)

    const [billingAddresses, setBillingAddresses] = useState([])
    const [shippingAddresses, setShippingAddresses] = useState([])

    const shopUserData = useSelector((state) => state.shopUserData.shopUserData)
    // console.log(shopUserData)

    useEffect(() => {
        if (shopUserData == null || Object.keys(shopUserData).length == 0) {
            navigate('/')
        }
    }, [])
    const [formData, setFormData] = useState({
        first_name: shopUserData.first_name,
        last_name: shopUserData.last_name,
        phone: shopUserData.phone,
        email: shopUserData.email,
        shipping_address_id: '',
        billing_address_id: '',
    })

    useEffect(() => {
        axios
            .get(`${BASE_URL}/user/address`, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${shopUserData.token}`,
                },
            })
            .then((res) => {
                setBillingAddresses(
                    res.data.data.filter((item) => item.type == 'Billing')
                )

                setShippingAddresses(
                    res.data.data.filter((item) => item.type == 'Shipping')
                )

                setFormData({
                    ...formData,
                    billing_address_id:
                        res.data.data.filter((item) => item.type == 'Billing')
                            .length > 0
                            ? res.data.data.filter(
                                  (item) => item.type == 'Billing'
                              )[0]?.id
                            : '',
                    shipping_address_id:
                        res.data.data.filter((item) => item.type == 'Shipping')
                            .length > 0
                            ? res.data.data.filter(
                                  (item) => item.type == 'Shipping'
                              )[0]?.id
                            : '',
                })
            })
    }, [])

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target

        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: value,
            })
        } else {
            setFormData({
                ...formData,
                [name]: value,
            })
        }
    }

    const location = useLocation()
    console.log(location)
    const shippingCost = location.state?.shipping || '10'

    const calculateTotal = () => {
        return cart.items.reduce(
            (total, item) => total + item.price * item.quantity,
            0
        )
    }

    const handleSubmit = (e) => {
        e.preventDefault()

        const cart_items = cart.items.map((item) => {
            return {
                slug: item.slug,
                quantity: item.quantity,
                measurement_size: item.variant.measurement_size,
                measurement_slug: item.size.slug,
                color_title: item.variant.color_title,
                color_code: item.variant.color_code,
                variant: item.variant.variant,
                shipping_amount: shippingCost,
                // shipping_amount: item.shipping_amount,
            }
        })

        let body = {
            products: cart_items,
            order_details: formData,
        }
        // console.log(body)

        const shippingTotal = cart.items.reduce(
            (total, item) => total + item.shipping_amount,
            0
        )
        // console.log(shippingTotal)
        body['order_details']['shipping_amount_total'] = shippingTotal
        body['order_details']['shipping_billing_same'] = false

        const headers = {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + shopUserData.token,
        }

        dispatch(showLoader())
        axios
            .post(`${BASE_URL}/user/orders`, body, {
                headers: headers,
            })
            .then((response) => {
                console.log(response)
                if (response.data.success) {
                    dispatch({ type: 'EMPTY_CART' })
                    goToThankyou() // TODO change to dispatch show Toast
                } else {
                    alert('An error occured. ', response.data.message) // TODO change to dispatch show Toast
                }
            })
            .finally(() => {
                dispatch(hideLoader())
            })
    }

    const goToThankyou = () => {
        navigate('/thankyou')
    }

    return (
        <Layout childrenClasses="pt-0 pb-0">
            <div className="checkout-page-wrapper w-full  pb-[60px]">
                <div className="w-full mb-5">
                    <PageTitle title="Checkout" />
                </div>
                <div className="checkout-main-content w-full">
                    <div className="container-x mx-auto">
                        <div className="w-full sm:mb-10 mb-5"></div>
                        <div className="w-full lg:flex lg:space-x-[30px]">
                            <div className="lg:w-1/2 w-full">
                                <div className="form-area">
                                    <form>
                                        <div className="sm:flex sm:space-x-5 items-center mb-6">
                                            <div className="sm:w-1/2  mb-5 sm:mb-0">
                                                <InputCom
                                                    label="First Name*"
                                                    placeholder="Demo Name"
                                                    inputClasses="w-full h-[50px]"
                                                    value={formData.first_name}
                                                    name="first_name"
                                                    required
                                                    inputHandler={(e) => {
                                                        handleChange(e)
                                                    }}
                                                />
                                            </div>
                                            <div className="flex-1">
                                                <InputCom
                                                    label="Last Name*"
                                                    placeholder="Demo Name"
                                                    inputClasses="w-full h-[50px]"
                                                    value={formData.last_name}
                                                    name="last_name"
                                                    required
                                                    inputHandler={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex space-x-5 items-center mb-6">
                                            <div className="flex-1">
                                                <InputCom
                                                    label="Email Address*"
                                                    placeholder="demoemial@gmail.com"
                                                    inputClasses="w-full h-[50px]"
                                                    value={formData.email}
                                                    name="email"
                                                    required
                                                    inputHandler={handleChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="flex space-x-5 items-center mb-6">
                                            <div className="w-1/2">
                                                <InputCom
                                                    label="Phone Number*"
                                                    placeholder="012 3  *******"
                                                    inputClasses="w-full h-[50px]"
                                                    value={formData.phone}
                                                    name="phone"
                                                    required
                                                    inputHandler={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                                    Shipping Details
                                </h1>
                                <h3 className="">Choose Shipping Address</h3>
                                <div class="flex flex-wrap mb-5">
                                    {shippingAddresses &&
                                        shippingAddresses.map((item) => {
                                            return (
                                                <div
                                                    key={item.id}
                                                    className="flex p-2 bg-white border-solid border-2 flex-col m-2
    input-field placeholder:text-sm text-sm px-6 text-dark-gray font-normal focus:ring-0 focus:outline-none w-[47%]
    input-wrapper border-qgray-border h-full overflow-hidden custom-radio"
                                                >
                                                    <div className="radio-box">
                                                        <input
                                                            type="radio"
                                                            name="shipping_address_id"
                                                            value={item.id}
                                                            checked={
                                                                formData.shipping_address_id ==
                                                                item.id
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            className="opacity-0 absolute h-5 w-5"
                                                        />
                                                        <span className="tick-mark"></span>
                                                    </div>
                                                    <div>
                                                        Address Name:{' '}
                                                        {item.title}
                                                    </div>
                                                    <div>
                                                        Street:{' '}
                                                        {item.street_address}
                                                    </div>
                                                    <div>
                                                        Zip Code:{' '}
                                                        {item.postal_code}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>

                                <h1 className="sm:text-2xl text-xl text-qblack font-medium ">
                                    Billing Details
                                </h1>
                                <h3>Choose Billing Address</h3>
                                <div class="flex flex-wrap">
                                    {billingAddresses &&
                                        billingAddresses.map((item) => {
                                            return (
                                                <div
                                                    key={item.id}
                                                    class="flex p-2 bg-white border-solid border-2 flex-col m-2
													input-field placeholder:text-sm text-sm px-6 text-dark-gray   font-normal  focus:ring-0 focus:outline-none w-[47%] relative
													input-wrapper border-qgray-border h-full overflow-hidden "
                                                >
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            name="billing_address_id"
                                                            value={item.id}
                                                            checked={
                                                                formData.billing_address_id ==
                                                                item.id
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            className="opacity-0 absolute h-5 w-5"
                                                        />
                                                        <span className="tick-mark"></span>
                                                    </div>
                                                    <div>
                                                        Address Name:{' '}
                                                        {item.title}
                                                    </div>
                                                    <div>
                                                        Street:{' '}
                                                        {item.street_address}
                                                    </div>
                                                    <div>
                                                        Zip Code:{' '}
                                                        {item.postal_code}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            </div>

                            <div className="flex-1 ">
                                <h1 className="sm:text-2xl text-xl text-qblack font-medium mb-5">
                                    Order Summary
                                </h1>

                                <div className="w-full px-10 py-[30px] border border-[#EDEDED] bg-white">
                                    <div className="sub-total mb-6">
                                        <div className=" flex justify-between mb-5">
                                            <p className="text-[13px] font-medium text-qblack uppercase">
                                                Product
                                            </p>
                                            <p className="text-[13px] font-medium text-qblack uppercase">
                                                total
                                            </p>
                                        </div>
                                        <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                                    </div>
                                    <div className="product-list w-full mb-[30px]">
                                        <ul className="flex flex-col space-y-5">
                                            {cart.items.map((item) => (
                                                <li
                                                    key={
                                                        item.slug +
                                                        item.color.code
                                                    }
                                                >
                                                    <div className="flex justify-between items-center">
                                                        <div>
                                                            <h4 className="text-[15px] text-qblack mb-2.5">
                                                                {item.name}
                                                                <sup className="text-[13px] text-qgray ml-2 mt-2">
                                                                    x
                                                                    {
                                                                        item.quantity
                                                                    }
                                                                </sup>
                                                            </h4>
                                                            <div
                                                                style={{
                                                                    fontSize:
                                                                        '0.7rem',
                                                                }}
                                                            >
                                                                {
                                                                    item.color
                                                                        .label
                                                                }
                                                            </div>
                                                            <div
                                                                style={{
                                                                    fontSize:
                                                                        '0.7rem',
                                                                }}
                                                            >{`${item.size.size} - ${item.size.height}" x ${item.size.width}" x ${item.size.length}"`}</div>
                                                            <div
                                                                style={{
                                                                    fontSize:
                                                                        '0.7rem',
                                                                }}
                                                            >{`${item.size.weight} Kg`}</div>
                                                        </div>
                                                        <div>
                                                            <span className="text-[15px] text-qblack font-medium">
                                                                $
                                                                {item.price *
                                                                    item.quantity}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="w-full h-[1px] bg-[#EDEDED]"></div>

                                    <div className="mt-[30px]">
                                        <div className=" flex justify-between mb-5">
                                            <p className="text-[13px] font-medium text-qblack uppercase">
                                                SUBTOTAL
                                            </p>
                                            <p className="text-[15px] font-medium text-qblack uppercase">
                                                ${calculateTotal()}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="w-full mt-[30px]">
                                        <div className="sub-total mb-6">
                                            <div className=" flex justify-between mb-5">
                                                <div>
                                                    <span className="text-xs text-qgraytwo mb-3 block">
                                                        SHIPPING
                                                    </span>
                                                    {/* <p className="text-base font-medium text-qblack">
                            Free Shipping
                          </p> */}
                                                </div>
                                                <p className="text-[15px] font-medium text-qblack">
                                                    +${shippingCost}
                                                </p>
                                            </div>
                                            <div className="w-full h-[1px] bg-[#EDEDED]"></div>
                                        </div>
                                    </div>

                                    <div className="mt-[30px]">
                                        <div className=" flex justify-between mb-5">
                                            <p className="text-2xl font-medium text-qblack">
                                                Total
                                            </p>
                                            <p className="text-2xl font-medium text-qred">
                                                $
                                                {calculateTotal() +
                                                    +shippingCost}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="shipping mt-[30px]">
                                        <ul className="flex flex-col space-y-1">
                                            <li>
                                                {' '}
                                                <div
                                                    style={{
                                                        paddingBottom: '1rem',
                                                    }}
                                                >
                                                    <img
                                                        width="318"
                                                        height="28"
                                                        src={`${process.env.PUBLIC_URL}/assets/images/payment-getways.png`}
                                                        alt="payment-getways"
                                                    />
                                                </div>
                                                <div className="flex space-x-2.5 items-center mb-5">
                                                    <div className="input-radio">
                                                        <input
                                                            type="radio"
                                                            name="price"
                                                            className="accent-pink-500"
                                                            id="bank"
                                                            checked="true"
                                                        />
                                                    </div>
                                                    <label
                                                        htmlFor="bank"
                                                        className="text-[18px] text-normal text-qblack"
                                                    >
                                                        Credit/Debit Cards or
                                                        Paypal
                                                    </label>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div
                                        className="w-full h-[50px] black-btn flex justify-center items-center"
                                        onClick={handleSubmit}
                                    >
                                        <span className="text-sm font-semibold">
                                            Place Order Now
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="w-[100%] mx-auto">
	  <ShippingCmp />
	  </div> */}
            {/* <span className="w-5 " style={{ transform: "scale(0.3)" }}>
                    <LoaderStyleOne />
                  </span> */}
        </Layout>
    )
}
