import React, { useEffect, useState } from 'react'
import './AddressModal.css'
import InputCom from '../InputCom'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
// import { useNavigate } from "react-router-dom";
import { BASE_URL } from '../../../utils/response'

function AddressModal({ isOpen, onClose }) {
    // const dispatch = useDispatch();
    // const navigate = useNavigate();
    const shopUserData = useSelector((state) => state.shopUserData.shopUserData)

    const [title, setTitle] = useState('')
    const [street, setStreet] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [type, setType] = useState('')
    const [stateId, setStateId] = useState('')
    const [cityId, setCityId] = useState('')

    const [cities, setCities] = useState([])
    const [states, setStates] = useState([])

    const modalClassName = isOpen ? 'modal-overlay open' : 'modal-overlay'

    // close x mark function ===>
    const close = () => {
        onClose()
    }

    // save address button handler ==>
    const onSubmit = async () => {
        // if (!title || !street || !stateId || !cityId || !zipCode || !type) {
        //   alert("All fields are required");
        //   return;
        // }

        const bodyFormData = new FormData()
        bodyFormData.append('title', title)
        bodyFormData.append('street_address', street)
        bodyFormData.append('state_id', stateId)
        bodyFormData.append('city_id', cityId)
        bodyFormData.append('type', type)
        bodyFormData.append('postal_code', zipCode)

        try {
            const res = await axios.post(
                `${BASE_URL}/user/address`,
                bodyFormData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${shopUserData.token}`,
                    },
                }
            )
            if (res.status === 200) {
                alert('Address saved successfully!')
                onClose() // Close the modal
            }

            console.log('Response data:', res.data)
            // Handle success (e.g., show success message, redirect user)
        } catch (error) {
            if (error.response) {
                alert(`Error: ${JSON.stringify(error.response.data)}`)
            } else {
                alert('Network Error: Please try again later.')
            }
        }
    }

    // Fetch states based on selected country
    const fetchStates = async (country) => {
        try {
            const apiRes = await axios.get(
                `${BASE_URL}/states?country=United States`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${shopUserData.token}`,
                    },
                }
            )
            setStates(apiRes?.data?.data)
        } catch (error) {
            console.error('Error fetching states:', error)
        }
    }

    // Fetch cities based on selected state
    const fetchCities = async (stateName) => {
        try {
            const apiRes = await axios.get(
                `${BASE_URL}/cities?state=${stateName}&country=United States`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${shopUserData.token}`,
                    },
                }
            )
            setCities(apiRes?.data?.data)
        } catch (error) {
            console.error('Error fetching cities:', error)
        }
    }

    const handleStateChange = async (value) => {
        setStateId(value)
        const stateName = states.find((item) => item.id == value)?.name
        fetchCities(stateName)
    }

    useEffect(() => {
        fetchStates()
    }, [])

    return (
        <div className={`${modalClassName} `}>
            <div className="modal-content-signup">
                <div className="lg:w-[572px] w-full bg-white flex flex-col justify-center sm:p-10 p-5 border border-[#E0E0E0]">
                    <div className="w-full">
                        <div className="title-area flex flex-col justify-center items-center relative text-center mb-4">
                            <h1 className="text-[24px] font-bold leading-[54px] text-qblack">
                                Save/Update Address
                            </h1>
                            <button onClick={close} className="close-button">
                                <span>
                                    <svg
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3L5 3.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L3.6 5L0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7C0.7 10.1 1.3 10.1 1.7 9.7L5 6.4L8.3 9.7C8.7 10.1 9.3 10.1 9.7 9.7C10.1 9.3 10.1 8.7 9.7 8.3L6.4 5L9.7 1.7C10.1 1.3 10.1 0.7 9.7 0.3Z"
                                            fill="#AAAAAA"
                                        />
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="input-area">
                            <div className="input-item mb-3">
                                <InputCom
                                    placeholder="Home, Office A, etc..."
                                    label="Title"
                                    name="title"
                                    type="text"
                                    className="input-label capitalize block  mb-2 text-sm text-gray-500"
                                    inputClasses="h-[40px]"
                                    value={title}
                                    inputHandler={(e) =>
                                        setTitle(e.target.value)
                                    }
                                />
                            </div>
                            <div className="input-item mb-3">
                                <InputCom
                                    placeholder="3rd Street..."
                                    label="Street"
                                    name="street"
                                    type="text"
                                    inputClasses="h-[40px]"
                                    className="input-label capitalize block  mb-2 text-sm text-gray-500"
                                    value={street}
                                    inputHandler={(e) =>
                                        setStreet(e.target.value)
                                    }
                                />
                            </div>
                            <div className="input-item mb-3">
                                <label
                                    className="input-label capitalize block  mb-2 text-sm text-gray-500"
                                    htmlFor="state"
                                >
                                    State
                                </label>
                                <select
                                    id="state"
                                    name="state"
                                    value={stateId}
                                    onChange={(e) =>
                                        handleStateChange(e.target.value)
                                    }
                                    // className="mt-1  w-full block pl-3 pr-10 py-2 text-base focus:outline-none sm:text-sm pt-[10px] pb-[10px]"

                                    className="input-field border-color placeholder:text-sm text-sm px-6 text-dark-gray w-full  font-normal bg-white focus:ring-0 focus:outline-none pt-[10px] pb-[10px]"
                                >
                                    <option value="" disabled>
                                        Select an option
                                    </option>
                                    {states.map((state) => (
                                        <option key={state.id} value={state.id}>
                                            {state.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="input-item mb-3">
                                <label
                                    htmlFor="city"
                                    className="text-sm text-gray-500 input-label capitalize block  mb-2"
                                >
                                    City
                                </label>
                                <select
                                    id="city"
                                    name="city"
                                    value={cityId}
                                    onChange={(e) => setCityId(e.target.value)}
                                    className="input-field border-color placeholder:text-sm text-sm px-6 text-dark-gray w-full  font-normal bg-white focus:ring-0 focus:outline-none pt-[10px] pb-[10px]"
                                >
                                    <option value="" disabled>
                                        Select an option
                                    </option>
                                    {cities.map((city) => (
                                        <option key={city.id} value={city.id}>
                                            {city.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="input-item mb-3 ">
                                <InputCom
                                    placeholder="Postal Code..."
                                    label="Zip Code"
                                    name="zipCode"
                                    type="number"
                                    inputClasses="h-[40px] "
                                    className="input-label capitalize block  mb-2 text-sm text-gray-500"
                                    value={zipCode}
                                    inputHandler={(e) =>
                                        setZipCode(e.target.value)
                                    }
                                />
                            </div>
                            <div className="input-item mb-3">
                                <label
                                    htmlFor="type"
                                    className="text-sm text-gray-500 input-label capitalize block mb-2"
                                >
                                    Type
                                </label>
                                <select
                                    id="type"
                                    name="type"
                                    value={type}
                                    onChange={(e) => setType(e.target.value)}
                                    className="input-field border-color placeholder:text-sm text-sm px-6 text-dark-gray w-full  font-normal bg-white focus:ring-0 focus:outline-none pt-[10px] pb-[10px] "
                                >
                                    <option
                                        className="text-white"
                                        value=""
                                        disabled
                                    >
                                        Select an option
                                    </option>
                                    <option value="General">General</option>
                                    <option value="Shipping">Shipping</option>
                                    <option value="Billing">Billing</option>
                                </select>
                            </div>
                        </div>
                        <div className="signin-area mb-3.5">
                            <div className="flex justify-center">
                                <button
                                    type="button"
                                    className="black-btn mb-6 text-sm text-white w-full h-[50px] font-semibold flex justify-center bg-purple items-center"
                                    onClick={onSubmit}
                                >
                                    <span>Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddressModal
