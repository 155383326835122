import ProductCardStyleOne from "../Helpers/Cards/ProductCardStyleOne";
import DataIteration from "../Helpers/DataIteration";
import Layout from "../Partials/LayoutHomeTwo";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../utils/response.js";

export default function AllProductPage() {
	const [products, setProducts] = useState([]);
	const [page, setPage] = useState(1);
	const [total, setTotal] = useState(0);

	const [filterForm, setFilterForm] = useState({
		subCategorySelected: "",
		sort: "price-DESC",
	});

	const data = useSelector((state) => state.search);

	useEffect(() => {
		axios
			.get(
				`${BASE_URL}/products-listing-api?per_page=9&page_no=${page}&search=${data.searchQuery}`
			)
			.then((response) => {
				let productList = response.data.data.records.map((item) => {
					return {
						slug: item.slug,
						title: item.title,
						image: item.ImgURL + item.thumbnail,
						price: item.price,
						review: item.rating ? item.rating : 5,
					};
				});
				setTotal(response.data?.data?.pagination?.total);
				setPage(response.data?.data?.pagination?.current_page);
				setProducts(productList);
			});
	}, [data]);

	const [filters, setFilter] = useState({
		mobileLaptop: false,
		gaming: false,
		imageVideo: false,
		vehicles: false,
		furnitures: false,
		sport: false,
		foodDrinks: false,
		fashion: false,
		toilet: false,
		makeupCorner: false,
		babyItem: false,
		apple: false,
		samsung: false,
		walton: false,
		oneplus: false,
		vivo: false,
		oppo: false,
		xiomi: false,
		others: false,
		sizeS: false,
		sizeM: false,
		sizeL: false,
		sizeXL: false,
		sizeXXL: false,
		sizeFit: false,
	});

	const checkboxHandler = (e) => {
		const { name } = e.target;
		setFilter((prevState) => ({
			...prevState,
			[name]: !prevState[name],
		}));
	};
	const [volume, setVolume] = useState({ min: 200, max: 500 });

	const [storage, setStorage] = useState(null);
	const filterStorage = (value) => {
		setStorage(value);
	};
	const [filterToggle, setToggle] = useState(false);

	return (
		<>
			<Layout>
				<div className="products-page-wrapper w-full">
					<div className="container-x mx-auto">
						<div className="w-full lg:flex lg:space-x-[30px]">
							<div className="lg:w-[270px]">
								{/* <ProductsFilter
                  filterToggle={filterToggle}
                  filterToggleHandler={() => setToggle(!filterToggle)}
                  filters={filters}
                  checkboxHandler={checkboxHandler}
                  volume={volume}
                  volumeHandler={(value) => setVolume(value)}
                  storage={storage}
                  filterstorage={filterStorage}
                  className="mb-[30px]"
                />  */}
								{}
								<div className="w-full hidden lg:block h-[295px]">
									<img
										src={`${process.env.PUBLIC_URL}/assets/images/ads-5.png`}
										alt=""
										className="w-full h-full object-contain"
									/>
								</div>
							</div>

							<div className="flex-1">
								<div className="products-sorting w-full bg-white md:h-[70px] flex md:flex-row flex-col md:space-y-0 space-y-5 md:justify-between md:items-center p-[30px] mb-[40px]">
									<div>
										<p className="font-400 text-[13px]">
											<span className="text-qgray"> Showing</span>{" "}
											{page * 9 - 8}–{page * 9} of {total} results
										</p>
									</div>
									<div className="flex space-x-3 items-center">
										<span className="font-400 text-[13px]">Sort by:</span>
										<div className="flex space-x-3 items-center">
											<select
												value={filterForm.sort}
												onChange={(event) => {
													sortHandler(event.target.value);
												}}
											>
												<option value="title-ASC">Name: A - Z</option>
												<option value="title-DESC">Name: Z - A</option>
												<option value="price-DESC">Price: High to Low</option>
												<option value="price-ASC">Price: Low to High</option>
											</select>
										</div>
									</div>
									<button
										onClick={() => setToggle(!filterToggle)}
										type="button"
										className="w-10 lg:hidden h-10 rounded flex justify-center items-center border border-qyellow text-qyellow"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											strokeWidth="2"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
											/>
										</svg>
									</button>
								</div>

								<div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1  xl:gap-[30px] gap-5 mb-[40px]">
									<DataIteration datas={products}>
										{({ datas }) => (
											<div
												data-aos="fade-up"
												key={datas.id}
											>
												<ProductCardStyleOne datas={datas} />
											</div>
										)}
									</DataIteration>
								</div>

								<div className="w-full h-[164px] overflow-hidden mb-[40px]">
									<img
										src={`${process.env.PUBLIC_URL}/assets/images/ads-6.png`}
										alt=""
										className="w-full h-full object-contain"
									/>
								</div>
								<div className="grid xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-[30px] gap-5 mb-[40px]">
									<DataIteration
										datas={products}
										startLength={6}
										endLength={15}
									>
										{({ datas }) => (
											<div
												data-aos="fade-up"
												key={datas.id}
											>
												<ProductCardStyleOne datas={datas} />
											</div>
										)}
									</DataIteration>
								</div>
								<div className="products-sorting w-full bg-white md:h-[70px] flex md:flex-row flex-col md:space-y-0 space-y-5 md:justify-between md:items-center p-[30px] ">
									<div>
										<p className="font-400 text-[13px]">
											<span className="text-qgray"> Showing</span>{" "}
											{page * 9 - 8}–{page * 9} of {total} results
										</p>
									</div>
									<div className="flex space-x-3 items-center">
										<span className="font-400 text-[13px]">Sort by:</span>
										<div className="flex space-x-3 items-center">
											<select
												value={filterForm.sort}
												onChange={(event) => {
													sortHandler(event.target.value);
												}}
											>
												<option value="title-ASC">Name: A - Z</option>
												<option value="title-DESC">Name: Z - A</option>
												<option value="price-DESC">Price: High to Low</option>
												<option value="price-ASC">Price: Low to High</option>
											</select>
										</div>
									</div>
									<button
										onClick={() => setToggle(!filterToggle)}
										type="button"
										className="w-10 lg:hidden h-10 rounded flex justify-center items-center border border-qyellow text-qyellow"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											strokeWidth="2"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
											/>
										</svg>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		</>
	);
}
