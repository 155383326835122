import React from 'react'
import DataIteration from '../../../Helpers/DataIteration'
import Star from '../../../Helpers/icons/Star'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { hideLoader, showLoader } from '../../../../store/actions'
import { useNavigate } from 'react-router-dom'
import { BASE_URL } from '../../../../utils/response'

export default function ReviewTab({ className, products }) {
    const navigate = useNavigate()
    const shopUserData = useSelector((state) => state.shopUserData.shopUserData)
    const dispatch = useDispatch()
    const [completedReviews, setCompletedReviews] = useState([])
    const [pendingReviews, setPendingReviews] = useState([])

    const [type, setType] = useState('')
    const [isModalOpen, setIsModalOpen] = useState(false)

    const openModal = () => {
        setIsModalOpen(true)
    }

    const closeModal = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        const headers = {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + shopUserData.token,
        }
        dispatch(showLoader())
        axios
            .get(`${BASE_URL}/users-reviews`, {
                headers: headers,
            })
            .then((response) => {
                if (response.data.success) {
                    setPendingReviews(response.data.data.pending_reviews)
                    setCompletedReviews(response.data.data.review_given)
                }
            })
            .catch((e) => console.log(e))
            .finally(() => {
                dispatch(hideLoader())
            })
    }, [])

    const addReview = (item) => {
        navigate(`/review/${item.id}/${item.product.slug}`, { state: item })
    }

    return (
        <>
            <div className="review-tab-wrapper w-full">
                <div className="">
                    <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                        <tbody>
                            {/* table heading */}
                            <tr className="text-[13px] font-medium text-black bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom uppercase">
                                <td className="py-4 pl-10 whitespace-nowrap w-80">
                                    Product Name
                                </td>
                                <td className="py-4 pl-10 whitespace-nowrap ">
                                    Order No.
                                </td>
                                <td className="py-4 pr-10 whitespace-nowrap ">
                                    Review
                                </td>
                                <td className="py-4 whitespace-nowrap ">
                                    Action
                                </td>
                            </tr>
                            {/* table heading end */}
                            {pendingReviews.map((item, index) => (
                                <tr
                                    key={item.product + index}
                                    className="bg-white border-b hover:bg-gray-50"
                                >
                                    <td className="pl-10  py-4 ">
                                        <div className="flex space-x-6 items-center">
                                            <div className="flex-1 flex flex-col">
                                                <p className="font-medium text-[15px] text-qblack">
                                                    {item.product.title}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="pl-10 py-4 ">
                                        <div className="flex space-x-6 items-center">
                                            <div className="flex-1 flex flex-col">
                                                <p className="font-medium text-[15px] text-qblack">
                                                    {item.order.order_number}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="  py-4">
                                        <div className="flex space-x-6 items-center">
                                            <div className="flex-1 flex flex-col">
                                                <p className="font-medium text-[15px] text-qblack">
                                                    {item.review
                                                        ? 'Completed'
                                                        : 'Pending'}
                                                </p>
                                            </div>
                                        </div>
                                    </td>

                                    <td className="  py-4 ">
                                        <button
                                            onClick={() => {
                                                addReview(item)
                                            }}
                                        >
                                            Add Review
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            {completedReviews.map((item, index) => (
                                <tr
                                    key={item.product + index}
                                    className="bg-white border-b hover:bg-gray-50"
                                >
                                    <td className="pl-10  py-4 ">
                                        <div className="flex space-x-6 items-center">
                                            <div className="flex-1 flex flex-col">
                                                <p className="font-medium text-[15px] text-qblack">
                                                    {item.product.title}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="pl-10 py-4 ">
                                        <div className="flex space-x-6 items-center">
                                            <div className="flex-1 flex flex-col">
                                                <p className="font-medium text-[15px] text-qblack">
                                                    {item.order.order_number}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="  py-4">
                                        <div className="flex space-x-6 items-center">
                                            <div className="flex-1 flex flex-col">
                                                <p className="font-medium text-[15px] text-qblack">
                                                    {item.review
                                                        ? 'Completed'
                                                        : 'Pending'}
                                                </p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="  py-4 ">
                                        {item.review ? (
                                            ''
                                        ) : (
                                            <button
                                                onClick={() => {
                                                    addReview(item)
                                                }}
                                            >
                                                Add Review
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
