import InputQuantityCom from '../Helpers/InputQuantityCom'
import { useDispatch, useSelector } from 'react-redux'
import {
    removeFromCart,
    incrementItemQuantity,
    decrementItemQuantity,
    showToast,
} from '../../store/actions'
import { Link } from 'react-router-dom'

export default function ProductsTable({ className }) {
    const cart = useSelector((state) => state.cart)
    const dispatch = useDispatch()

    const handleRemoveFromCart = (item) => {
        dispatch(removeFromCart(item))
    }

    const increment = (item) => {
        let product = cart.items.find(
            (product) =>
                product.slug == item.slug &&
                product.variant.variant == item.variant.variant
        )
        if (product && item.quantity < product.variant.quantity) {
            dispatch(incrementItemQuantity(item))
        } else {
            dispatch(showToast('Stock limit reached', 'warning', 1000))
        }
    }

    const decrement = (item) => {
        dispatch(decrementItemQuantity(item))
    }

    return (
        <div className={`w-full ${className || ''}`}>
            <div className="relative w-full overflow-x-auto border border-[#EDEDED]">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                    <tbody>
                        {/* table heading */}
                        <tr className="text-[13px] font-medium text-black bg-[#F6F6F6] whitespace-nowrap px-2 border-b default-border-bottom uppercase">
                            <td className="py-4 pl-10 block whitespace-nowrap min-w-[300px]">
                                product
                            </td>
                            {/* <td className="py-4 whitespace-nowrap text-center">color</td> */}
                            {/* <td className="py-4 whitespace-nowrap text-center">size</td> */}
                            <td className="py-4 whitespace-nowrap text-center">
                                price
                            </td>
                            <td className="py-4 whitespace-nowrap  text-center">
                                quantity
                            </td>
                            <td className="py-4 whitespace-nowrap  text-center">
                                total
                            </td>
                            <td className="py-4 whitespace-nowrap text-right w-[114px]"></td>
                        </tr>
                        {/* table heading end */}
                        {cart.items.map((item) => (
                            <tr
                                key={item.slug + item.color.code}
                                className="bg-white border-b hover:bg-gray-50"
                            >
                                <td className="pl-10  py-4  w-[380px]">
                                    <div className="flex space-x-6 items-center">
                                        <div className="w-[80px] h-[80px] overflow-hidden flex justify-center items-center border border-[#EDEDED]">
                                            <img
                                                src={item.image}
                                                alt="product"
                                                className="w-full h-full object-contain"
                                            />
                                        </div>
                                        <div className="flex-1 flex flex-col">
                                            <p className="font-medium text-[15px] text-qblack">
                                                <Link
                                                    to={{
                                                        pathname: `/product/${item.slug}`,
                                                    }}
                                                    state={{ product: item }}
                                                >
                                                    {item.name}
                                                </Link>
                                            </p>
                                            <div style={{ fontSize: '0.7rem' }}>
                                                {item.color.label}
                                            </div>
                                            <div
                                                style={{ fontSize: '0.7rem' }}
                                            >{`${item.size.size} - ${item.size.height}" x ${item.size.width}" x ${item.size.length}"`}</div>
                                            <div
                                                style={{ fontSize: '0.7rem' }}
                                            >{`${item.size.weight} Kg`}</div>
                                        </div>
                                    </div>
                                </td>
                                {/* <td className="text-center py-4 px-2">
                <div className=" flex justify-center items-center">
                  <span style={{ background: `${item.color.code}` }} className={`w-[20px] h-[20px] block rounded-full`}></span>
                </div>
              </td> */}
                                {/* <td className="text-center py-4 px-2">
                <div className="flex space-x-1 items-center justify-center">
                  <span className="text-[15px] font-normal">Small</span>
                </div>
              </td> */}
                                <td className="text-center py-4 px-2">
                                    <div className="flex space-x-1 items-center justify-center">
                                        <span className="text-[15px] font-normal">
                                            {item.price}
                                        </span>
                                    </div>
                                </td>
                                <td className=" py-4 text-center">
                                    <div className="flex justify-center">
                                        <div className="flex justify-around items-center border border-qgray-border">
                                            <button
                                                onClick={() => decrement(item)}
                                                type="button"
                                                className="px-4 text-base text-qgray"
                                            >
                                                -
                                            </button>
                                            <span className="text-qblack">
                                                {item.quantity}
                                            </span>
                                            <button
                                                onClick={() => increment(item)}
                                                type="button"
                                                className="px-4 text-base text-qgray"
                                            >
                                                +
                                            </button>
                                        </div>
                                    </div>
                                </td>
                                <td className="text-right py-4">
                                    <div className="flex space-x-1 items-center justify-center">
                                        <span className="text-[15px] font-normal">
                                            {item.quantity * item.price}
                                        </span>
                                    </div>
                                </td>
                                <td className="text-right py-4">
                                    <div className="flex space-x-1 items-center justify-center">
                                        <span
                                            onClick={() =>
                                                handleRemoveFromCart(item)
                                            }
                                        >
                                            <svg
                                                width="10"
                                                height="10"
                                                viewBox="0 0 10 10"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M9.7 0.3C9.3 -0.1 8.7 -0.1 8.3 0.3L5 3.6L1.7 0.3C1.3 -0.1 0.7 -0.1 0.3 0.3C-0.1 0.7 -0.1 1.3 0.3 1.7L3.6 5L0.3 8.3C-0.1 8.7 -0.1 9.3 0.3 9.7C0.7 10.1 1.3 10.1 1.7 9.7L5 6.4L8.3 9.7C8.7 10.1 9.3 10.1 9.7 9.7C10.1 9.3 10.1 8.7 9.7 8.3L6.4 5L9.7 1.7C10.1 1.3 10.1 0.7 9.7 0.3Z"
                                                    fill="#AAAAAA"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
