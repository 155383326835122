const initialState = {
    loading: false,
    toast: {
        show: false,
        message: '',
        type: '',
        duration: 0,
    },
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW':
            return {
                ...state,
                loading: true,
            }
        case 'HIDE':
            return {
                ...state,
                loading: false,
            }
        case 'TOAST_SHOW':
            return {
                ...state,
                toast: {
                    show: true,
                    message: action.payload.message,
                    duration: action.payload.duration,
                    type: action.payload.type,
                },
            }
        case 'TOAST_HIDE': {
            return {
                ...state,
                toast: {
                    show: false,
                    message: action.payload.message,
                    duration: action.payload.duration,
                    type: action.payload.type,
                },
            }
        }
        default:
            return state
    }
}

export default appReducer
